// External
import { View, Text } from '@react-pdf/renderer';

// Project
import { toCurrency } from 'src/utils/stringFormatter';
import pdfStyles from 'src/themes/pdfStyles';

interface AmountCellProps {
  amount: number;
  width: number;
  paddingLeft?: number;
  paddingRight?: number;
  bold?: boolean;
}

const { flex_row, justify_between, font_bold } = pdfStyles;
export const AmountCell = ({ amount, width, paddingLeft, paddingRight, bold }: AmountCellProps) => (
  <View
    style={[
      {
        width: width,
        paddingLeft: paddingLeft || 0,
        paddingRight: paddingRight || 0,
        fontFamily: bold && font_bold.fontFamily,
      },
      flex_row,
      justify_between,
    ]}
  >
    <Text>$</Text>
    <Text>{toCurrency(amount)}</Text>
  </View>
);
