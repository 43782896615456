// External
import { Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

// Project
import PATHS from 'src/config/paths';

export const FaqButton = () => {
  return (
    <Button
      variant="contained"
      size="large"
      startIcon={<HelpIcon />}
      className="bg-white text-lg text-primary"
      onClick={() => window.open(PATHS.faq, '_blank')}
    >
      FAQ
    </Button>
  );
};
