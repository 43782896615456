// External
import { Button } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import { useLocation, useNavigate, useMatch, useParams } from 'react-router-dom';

// Project
import PATHS from 'src/config/paths';

export const DefaultError = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch(location.pathname);
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const route = isAppUserAgency ? PATHS.agencySearch : PATHS.customerCases;

  const handleGoBack = () => {
    const path = match.pathname;
    if (path === PATHS.agencySearch || path === PATHS.customerCases) {
      window.location.reload();
    } else {
      navigate(route);
    }
  };

  return (
    <div className="mt-10 flex flex-col items-center justify-center">
      <ReportIcon style={{ fontSize: 128, color: '#9E9E9E' }} />
      <div className="font-bold">Internal Server Error. Something went wrong</div>
      <p>Date & Time: {new Date().toString()}</p>
      <Button variant="contained" onClick={handleGoBack}>
        Go back
      </Button>
    </div>
  );
};
