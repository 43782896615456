// External
import { Pagination } from '@mui/material';

// Mui-Datatable expects 0-indexed page number but Pagination starts at 1
export const DefaultPaginationFooter = (props) => {
  const { page, rowCount, rowsPerPage, changePage } = props;
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  if (totalPages > 1) {
    return (
      <Pagination
        shape="rounded"
        color="primary"
        count={totalPages}
        showFirstButton
        showLastButton
        page={page + 1}
        onChange={(event, page) => {
          return changePage(page - 1);
        }}
      />
    );
  } else {
    return null;
  }
};
