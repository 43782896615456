import { Button, Dialog, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

interface ModalProps {
  showDialog: boolean;
  email: string;
  loading: boolean;
  handleClose: () => void;
  handleOk: () => void;
}

export const ConfirmModal = ({ showDialog, email, loading, handleClose, handleOk }: ModalProps) => {
  return (
    <Dialog open={showDialog}>
      <Paper className="w-[50vw] max-w-[inherit]">
        <div className="border-x-0 border-b-[1px] border-t-0 border-solid border-[#E0E0E0] p-6">
          <IconButton className="absolute right-3 top-4" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div className="flex items-center gap-2">
            <ErrorRoundedIcon aria-label="Warning" className="fill-orange-600" />
            <h2 className="m-0 text-lg font-semibold">Unlock Account</h2>
          </div>
          <p className="ml-8 font-semibold">{email}</p>
          <p className="my-0 ml-8">
            Are you sure you want to unlock this account? Once unlocked, the customer will regain
            access to the portal and be able to attempt signing in again.
          </p>
        </div>
        <div className="my-3 flex justify-center">
          <div className="mt-3 flex justify-end gap-4">
            <Button className="p-2" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton className="p-2" loading={loading} onClick={handleOk} variant="contained">
              <span>Unlock</span>
            </LoadingButton>
          </div>
        </div>
      </Paper>
    </Dialog>
  );
};
