// External
import { Image, Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import SampleCheck from 'src/assets/images/sample-check.png';

// Feature
import { AmountCell } from '../PDF/AmountCell';
import { VoucherData } from '../../types';

const tableProp = {
  // Header widths
  balanceWidth: 400,
  amountDueWidth: 100,
};

const {
  font_bold,
  text_lg,
  pb_4,
  flex_row,
  pl_3,
  pb_3,
  p_2,
  border_t,
  mt_3,
  mb_3,
  border_slate_600,
} = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ minWidth: tableProp.balanceWidth }, font_bold]}>Balance</Text>
    <Text style={[{ width: tableProp.amountDueWidth }, font_bold]}>Amount Due</Text>
  </View>
);

const TableBody = ({ voucherInfo }) => {
  const { monthly_amount_due, minimum_amount_due } = voucherInfo as VoucherData;
  return (
    <>
      <View style={[flex_row, p_2, border_t, border_slate_600]}>
        <Text style={{ minWidth: tableProp.balanceWidth }}>Remaining Recoupment Balance</Text>
        <AmountCell width={tableProp.amountDueWidth} amount={monthly_amount_due} />
      </View>
      <View style={[flex_row, p_2, border_t, border_slate_600]}>
        <Text style={{ minWidth: tableProp.balanceWidth }}>Recoupment Amount Due this Month</Text>
        <AmountCell width={tableProp.amountDueWidth} amount={minimum_amount_due} />
      </View>
    </>
  );
};

export const PayByCheck = ({ voucherInfo }: { voucherInfo: VoucherData }) => {
  if (voucherInfo === null) {
    return <Text style={[font_bold, text_lg, pb_4]}>No info available.</Text>;
  }

  return (
    <>
      <Text style={[font_bold, text_lg, pb_4]}>Pay by Check</Text>
      <TableHeader />
      <TableBody voucherInfo={voucherInfo} />
      <Text style={mt_3}>
        Make your check Payable to: <Text style={font_bold}>Child Support Enforcement Agency</Text>
      </Text>
      <Text style={mt_3}>Mail Check to:</Text>
      <Text style={font_bold}>Child Support Enforcement Agency</Text>
      <Text style={font_bold}>P.O. Box 1860</Text>
      <Text style={font_bold}>Honolulu, HI&nbsp; 96805-1860</Text>
      <Text style={[mt_3, mb_3]}>
        Be sure to include your PIN in the "For" or "Memo" field in the check's lower left corner.
      </Text>
      <Image src={SampleCheck} style={{ width: 280 }} />
    </>
  );
};
