// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';

// Feature
import { AmountCell } from '../../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  dateWidth: 130,
  checkWidth: 140,
  distributionWidth: 140,
  amountWidth: 70,
  totalWidth: 410,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t, text_right } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ minWidth: tableProp.dateWidth }]}>Issued Date</Text>
    <Text style={[{ minWidth: tableProp.checkWidth }]}>Check #/EFT Trans #</Text>
    <Text style={[{ minWidth: tableProp.distributionWidth }]}>Distribution Type</Text>
    <Text style={[{ width: tableProp.amountWidth }, text_right]}>Amount</Text>
  </View>
);

const TableBody = ({ support }) => {
  const cpDisbInfo = support[0].cp_disb_info;
  const isSingleDisbInfo = cpDisbInfo.length === 1;

  if (isSingleDisbInfo) {
    return (
      <View style={[flex_row, p_2, pl_3, border_t]} key={`cp-0`}>
        <Text style={{ minWidth: tableProp.dateWidth }}>--</Text>
        <Text style={{ minWidth: tableProp.checkWidth }}>--</Text>
        <Text style={{ minWidth: tableProp.distributionWidth }}>--</Text>
        <AmountCell width={tableProp.amountWidth} amount={0} />
      </View>
    );
  }

  return cpDisbInfo.slice(0, -1).map((support_info, key) => (
    <View style={[flex_row, p_2, pl_3, border_t]} key={`cp-${key}`}>
      <Text style={{ minWidth: tableProp.dateWidth }}>
        {formatDate(support_info['Issued Date'], 'MMMM D, YYYY', 'MMMM D, YYYY')}
      </Text>
      <Text style={{ minWidth: tableProp.checkWidth }}>
        {support_info['Check #/EFT Trans #'].toString()}
      </Text>
      <Text style={{ minWidth: tableProp.distributionWidth }}>
        {support_info['Distribution Type']}
      </Text>
      <AmountCell width={tableProp.amountWidth} amount={support_info.Amount} />
    </View>
  ));
};

const TableFooter = ({ support }) => {
  return (
    <View style={[border_t, p_2, pl_3, flex_row]}>
      <Text style={[{ minWidth: tableProp.totalWidth }]}>Total Disbursed</Text>
      <AmountCell
        amount={support[0].cp_disb_info.slice(-1)[0].Amount}
        width={tableProp.amountWidth}
      />
    </View>
  );
};

export const CurrentSupportTable = ({ support }) => {
  if (support[0].case_info === null) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>No Disbursement Paid</Text>
        <Text>No disbursements found in the last quarter.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>
        Disbursements Paid to: {support[0]?.case_info?.[0]?.cp_name || ''}
      </Text>
      <TableHeader />
      <TableBody support={support} />
      <TableFooter support={support} />
    </>
  );
};
