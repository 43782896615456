const AWS_BASE_CONFIG = {
  region: process.env.REACT_APP_AWS_IDENTITY_REGION,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  endpoint: 'https://cognito-idp.us-west-2.amazonaws.com',
};

export const AWS_CUSTOMER_CONFIG = {
  ...AWS_BASE_CONFIG,
  userPoolId: process.env.REACT_APP_CUSTOMER_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_CUSTOMER_AWS_USER_POOL_WEB_CLIENT_ID,
};

export const AWS_AGENCY_CONFIG = {
  ...AWS_BASE_CONFIG,
  userPoolId: process.env.REACT_APP_AGENCY_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AGENCY_AWS_USER_POOL_WEB_CLIENT_ID,
};

export const AWS_API_ENDPOINTS = {
  endpoints: [
    {
      name: 'cms',
      endpoint: process.env.REACT_APP_AWS_API_CMS_URL,
    },
    {
      name: 'case',
      endpoint: process.env.REACT_APP_AWS_API_CUSTOMER_PORTAL_URL,
    },
    {
      name: 'hic',
      endpoint: process.env.REACT_APP_AWS_API_HIC_URL,
    },
  ],
};
