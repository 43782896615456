// External
import { useEffect, useState, useRef } from 'react';
import {
  Avatar as MaterialAvatar,
  Box,
  IconButton,
  MenuItem,
  Tooltip,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Typography,
} from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { useNavigate, useParams } from 'react-router-dom';

// Project
import PATHS from 'src/config/paths';
import { signOut } from 'src/lib/auth';

export function Avatar() {
  const navigate = useNavigate();
  const { appUser } = useParams();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const onClickMyProfile = (event: Event | React.SyntheticEvent) => {
    handleClose(event);
    navigate(PATHS.profile(appUser));
  };

  const onClickLogOut = async (event: Event | React.SyntheticEvent) => {
    handleClose(event);
    signOut();
    navigate(PATHS.signIn(appUser));
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open profile">
        <IconButton
          disableRipple
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          sx={{ p: 0 }}
          ref={anchorRef}
        >
          <MaterialAvatar
            sx={{
              backgroundColor: 'white',
              width: 30,
              height: 30,
            }}
            alt="Profile"
          >
            <PermIdentityOutlinedIcon fontSize="large" sx={{ color: '#616161' }} />
          </MaterialAvatar>
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper sx={{ marginTop: '5px' }} elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={onClickMyProfile}>
                    <Typography textAlign="center">My Profile</Typography>
                  </MenuItem>
                  <MenuItem onClick={onClickLogOut}>
                    <Typography textAlign="center">Log Out</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
