// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';

// Feature
import { AmountCell } from '../../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  reimburseWidth: 350,
  amountWidth: 80,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t, text_right } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ minWidth: tableProp.reimburseWidth }]}>Reimbursements</Text>
    <Text style={[{ width: tableProp.amountWidth }, text_right]}>Amount</Text>
  </View>
);

const TableBody = ({ payments }) => {
  const reimbursementInfo = payments[0].reimbursement_info;

  if (reimbursementInfo === null) {
    return (
      <View style={[flex_row, p_2, pl_3, border_t]} key={`reimburse-0`}>
        <Text style={{ minWidth: tableProp.reimburseWidth }}>--</Text>
        <AmountCell width={tableProp.amountWidth} amount={0} />
      </View>
    );
  }

  return reimbursementInfo.slice(0, -1).map((re_info, index) => (
    <View style={[flex_row, p_2, pl_3, border_t]} key={`reimburse-${index}`}>
      <Text style={{ minWidth: tableProp.reimburseWidth }}>{re_info.reimbursements}</Text>
      <AmountCell width={tableProp.amountWidth} amount={re_info.amount} />
    </View>
  ));
};

const TableFooter = ({ payments }) => {
  const reimbursementInfo = payments[0].reimbursement_info;
  if (reimbursementInfo !== null) {
    return (
      <View style={[border_t, p_2, pl_3, flex_row]}>
        <Text style={[{ minWidth: tableProp.reimburseWidth }]}>
          {payments[0].reimbursement_info.slice(-1)[0].reimbursements}
        </Text>
        <AmountCell
          amount={payments[0].reimbursement_info.slice(-1)[0].amount}
          width={tableProp.amountWidth}
        />
      </View>
    );
  }
  return (
    <View style={[border_t, p_2, pl_3, flex_row]}>
      <Text style={[{ minWidth: tableProp.reimburseWidth }]}>Total Reimbursements</Text>
      <AmountCell amount={0} width={tableProp.amountWidth} />
    </View>
  );
};

export const ReimbursementTable = ({ payments }) => {
  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>
        Summary of Reimbursements to the Welfare Program
      </Text>
      <TableHeader />
      <TableBody payments={payments} />
      <TableFooter payments={payments} />
    </>
  );
};
