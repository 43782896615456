// External
import { API } from 'aws-amplify';

// Project
import { FetchApiType } from '../types';

// Local
import * as Auth from './auth';

// NOTE: API uses axios under the hood
export const getHicData = async (path: string, queryParameters: object) => {
  try {
    return await fetchData('hic', path, queryParameters);
  } catch (err) {
    console.log('getHicDataError', err.response);
    throw err;
  }
};

export const putHicData = async (path: string, mutationParameters: object) => {
  try {
    return await putData('hic', path, mutationParameters);
  } catch (err) {
    console.log('putHicDataError', err.response);
    throw err;
  }
};

export const putCaseData = async (path: string, mutationParameters: object) => {
  try {
    return await putData('case', path, mutationParameters);
  } catch (err) {
    console.log('putHicDataError', err.response);
    throw err;
  }
};

export const postHicData = async (path: string, mutationParameters: object) => {
  try {
    let token = await Auth.getToken();
    let res = await API.post('hic', path, {
      headers: {
        Authorization: token,
      },
      body: mutationParameters,
    });
    return res.message;
  } catch (err) {
    console.log('postHicDataError', err.response);
    throw err;
  }
};

export const fetchCaseData = async (path: string) => {
  try {
    return await fetchData('case', path, {});
  } catch (err) {
    console.log('portal error', err.response);
    let error = err.response;
    if (error.status < 500) {
      throw new Error('Unauthorized');
    } else {
      throw new Error('Server Error. Please try again later.');
    }
  }
};

export const fetchData = async (type: FetchApiType, path: string, queryParameters: object) => {
  try {
    let token = await Auth.getToken();
    let res = await API.get(type, path, {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryParameters,
    });

    return res.message;
  } catch (err) {
    console.log('fetchData', err.response);
    throw err;
  }
};

export const putData = async (type: FetchApiType, path: string, mutationParameters: object) => {
  try {
    let token = await Auth.getToken();
    let res = await API.put(type, path, {
      headers: {
        Authorization: token,
      },
      body: mutationParameters,
    });
    return res.message;
  } catch (err) {
    console.log('putDataError', err.response);
    throw err;
  }
};
