// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import PaymentsPendingImg from '../../assets/PaymentsPending.webp';

export const PaymentsPending = () => {
  return (
    <Accordion summary="How do I view my Payments Pending?">
      <p className="mt-0">
        NCP payments which are on hold (not applied), are shown in Payments Pending.
      </p>
      <LazyLoadImage src={PaymentsPendingImg} alt="payments pending" height={350} />
    </Accordion>
  );
};
