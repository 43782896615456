// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import ForgotPasswordImg from '../../assets/ForgotPassword.webp';
import ResetPassword from '../../assets/ResetPassword.webp';

export const ForgotPassword = () => {
  return (
    <Accordion summary="I forgot my password, how do I change it?">
      <p className="mb-4 mt-0">
        When you forget your password, you can reset it by clicking on the “Forgot Password” link
        right under the Password text box. After that, you will be prompted to enter your email
        address. A reset code will be sent to your email.
      </p>
      <LazyLoadImage
        src={ForgotPasswordImg}
        alt="forgot password page"
        height={400}
        className="mb-4"
      />
      <LazyLoadImage src={ResetPassword} alt="reset password page" height={550} />
    </Accordion>
  );
};
