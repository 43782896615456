// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import HearingsImg from '../../assets/Hearings.webp';

export const Hearings = () => {
  return (
    <Accordion summary="How do I access my Hearings information?">
      <p className="mt-0">
        Click on the Hearings tab to see if you have any hearings or meetings scheduled.
      </p>
      <LazyLoadImage src={HearingsImg} alt="hearings" height={350} imgClassName="h-auto w-full" />
    </Accordion>
  );
};
