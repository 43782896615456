// External
import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Project
//// Config
import PATHS from 'src/config/paths';
//// Layout
import { MainLayout, CaseDetailsLayout, CaseLayout } from 'src/layout';
//// Container
import {
  AgencyOnlyRoute,
  ProtectedRoute,
  SuspenseContainer,
  AwsConfigContainer,
} from 'src/containers';
//// Components
import { NotFoundError } from 'src/components/Error';

// Features
//// FAQ
import { FaqRoutes } from 'src/features/faq';
//// UAM
import { AuthRoutes } from 'src/features/auth';
//// Enforcements
import { Enforcements } from 'src/features/enforcements';
//// Forms
import { FormsRoutes } from 'src/features/forms';
//// Case Status
import { CaseStatus } from 'src/features/case-status';
//// Agency Search
import { AgencySearch } from 'src/features/agency-search';
//// Agency Configurations
// NOTE: Disabling agency modify until new requirements are refined
// import { AgencyModifyRoutes } from 'src/features/agency-modify';
//// Agency Unlock
import { AgencyUnlockRoutes } from 'src/features/agency-unlock';
import RedirectLink from './RedirectLink';
//// Agency Form Report - disabling because out of scope for this phase
// import { AgencyFormReport } from 'src/features/agency-form-report';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport: () => any) =>
  lazy(async () => {
    const lAZY_RELOADED_VARIABLE_NAME = 'csea-retry-lazy-refreshed';
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(lAZY_RELOADED_VARIABLE_NAME) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(lAZY_RELOADED_VARIABLE_NAME, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(lAZY_RELOADED_VARIABLE_NAME, 'true');
        window.location.reload();
        return null;
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

//// Cases
const Cases = lazyRetry(() =>
  import(/* webpackChunkName: "cases" */ 'src/features/misc/routes').then((module) => ({
    default: module.Cases,
  }))
);

//// Hearings
//import Hearings from 'src/features/hearings';
const Hearings = lazyRetry(
  () => import(/* webpackChunkName: "hearings" */ 'src/features/hearings')
);

//// Profile
const ProfileRoutes = lazyRetry(() =>
  import(/* webpackChunkName: "profile-route" */ 'src/features/profile').then((module) => ({
    default: module.ProfileRoutes,
  }))
);

//// Payments
const PaymentsRoutes = lazyRetry(() =>
  import(/* webpackChunkName: "payments-route" */ 'src/features/payments').then((module) => ({
    default: module.PaymentsRoutes,
  }))
);

//// Support Balance
const SupportBalanceRoutes = lazyRetry(() =>
  import(/* webpackChunkName: "support-balance-route" */ 'src/features/support-balance').then(
    (module) => ({
      default: module.SupportBalanceRoutes,
    })
  )
);

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace={true} to={PATHS.signIn('customer')} />} />

        {/* agency or customer */}
        <Route path=":appUser" element={<AwsConfigContainer />}>
          <Route path="auth/*" element={<AuthRoutes />} />

          <Route element={<ProtectedRoute />}>
            <Route element={<MainLayout />}>
              <Route element={<SuspenseContainer />}>
                <Route element={<AgencyOnlyRoute />}>
                  <Route path="search" element={<AgencySearch />} />
                  {/* <Route path="form-report" element={<AgencyFormReport />} /> */}
                  {/* <Route path="modify/*" element={<AgencyModifyRoutes />} /> */}
                  <Route path="unlock/*" element={<AgencyUnlockRoutes />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path=":userIdentifier" element={<MainLayout />}>
              <Route element={<SuspenseContainer />}>
                <Route path="profile/*" element={<ProfileRoutes />} />
                <Route path="support-balance/*" element={<SupportBalanceRoutes />} />
              </Route>
              <Route path="cases">
                <Route element={<SuspenseContainer />}>
                  <Route index element={<Cases />} />
                </Route>
                <Route element={<CaseLayout />}>
                  <Route path=":caseId" element={<CaseDetailsLayout />}>
                    <Route element={<SuspenseContainer />}>
                      <Route path="payments/*" element={<PaymentsRoutes />} />
                      <Route path="hearings" element={<Hearings />} />
                    </Route>
                    <Route path="enforcements" element={<Enforcements />} />

                    <Route path="forms/*" element={<FormsRoutes />} />
                    <Route path="status" element={<CaseStatus />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route element={<MainLayout />}>
            <Route path="faq/*" element={<FaqRoutes />} />
            <Route path="*" element={<NotFoundError />} />
          </Route>
        </Route>

        <Route path="/redirect-app/*" element={<RedirectLink />} />
      </Routes>
    </>
  );
};

export default App;
