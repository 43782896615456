// External
import { Grid } from '@mui/material';

// Project
import { doesALocalAppUserExist } from 'src/utils/localStorage';

// Local
import { NavTabs } from './NavTabs';
import { Avatar } from './Avatar';

export const MainNavBar = () => {
  const localAppUserExist = doesALocalAppUserExist();

  return (
    <Grid container className="items-center justify-between bg-primary px-5">
      <Grid item>
        <NavTabs />
      </Grid>
      <Grid item>{localAppUserExist && <Avatar />}</Grid>
    </Grid>
  );
};
