// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import PaymentsAppliedImg from '../../assets/PaymentsApplied.webp';

export const PaymentsApplied = () => {
  return (
    <Accordion summary="How do I view my Payments Applied?">
      <p className="mt-0">Payments which are applied to CP cases are shown in Payments Applied.</p>
      <LazyLoadImage src={PaymentsAppliedImg} alt="payments applied" height={350} />
    </Accordion>
  );
};
