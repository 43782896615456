// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import EnforcementsImg from '../../assets/Enforcements.webp';

export const Enforcement = () => {
  return (
    <Accordion summary="How do I view the Enforcement actions on my cases?">
      <p className="mt-0">
        Click on the Enforcement tab to see any enforcement actions initiated on your case.
      </p>
      <LazyLoadImage src={EnforcementsImg} alt="enforcement page" height={300} />
      <p>
        NCP customer can view list of enforcement action taken against them including their
        outstanding balance.
      </p>
    </Accordion>
  );
};
