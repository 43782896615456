// External
import { Auth } from 'aws-amplify';

// Project
import { clearLocalAppUser } from 'src/utils/localStorage';

// Local
import { clearCache } from './cache';

export const getToken = async () => {
  try {
    let user = await Auth.currentSession();
    return user.getIdToken().getJwtToken();
  } catch (e) {
    console.log('getToken error', e.message);
  }
};

export const getEmail = async () => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser();
    return attributes.email;
  } catch (e) {
    console.log('getEmail error', e);
  }
};

export const signOut = async () => {
  try {
    clearCache();
    clearLocalAppUser();
    await Auth.signOut();
  } catch (e) {
    console.log('signOut error', e);
  }
};
