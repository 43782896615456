// Project
import { useHicData } from 'src/hooks';
import { FormList } from '../types';

export const useFormList = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const response = useHicData<Array<FormList>>('/form/list', queryParameters);
  console.log('useFormList', response);
  return response;
};
