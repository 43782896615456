// External
import { Button, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';

// Features
import { LastFirstNameTable } from './components/SearchByLastAndFirstName';
import { LastNameTable } from './components/SearchByLastName';
import { PinTable } from './components/SearchByPin';
import { CaseNumberTable } from './components/SearchByCaseNumber';

export const AgencySearch = () => {
  const [page, setPage] = useState(0);
  const [searchBy, setSearchBy] = useState('1');
  const [textField, setTextField] = useState('');
  const [activeSearch, setActiveSearch] = useState({
    searchBy: '1',
    textField: '',
  });

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSearchBy(String(event.target.value));
    setTextField('');
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextField(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setActiveSearch({ searchBy, textField });
    setPage(0);
  };

  return (
    <main>
      <h1 className="text-2xl">CSEA Admin - Account Search</h1>
      <p>Access to any account in the system via this Account Search page</p>
      <label htmlFor="search-by-input" className="font-bold">
        Search By
      </label>
      <div className="flex gap-3">
        <Select
          id="search-by-select"
          value={searchBy}
          onChange={handleSelectChange}
          size="small"
          className="w-1/4"
          inputProps={{ id: 'search-by-input' }}
        >
          <MenuItem value={1}>Last Name</MenuItem>
          <MenuItem value={2}>Last Name, First Name</MenuItem>
          <MenuItem value={3}>PIN</MenuItem>
          <MenuItem value={4}>Case ID</MenuItem>
        </Select>
        <form onSubmit={handleSubmit} className="w-1/2" id="search-form">
          <TextField
            id="outlined-basic"
            label="Enter search term"
            variant="outlined"
            size="small"
            fullWidth
            value={textField}
            onChange={handleTextChange}
          />
        </form>
        <Button variant="contained" size="small" className="w-1/4" form="search-form" type="submit">
          Search
        </Button>
      </div>
      {activeSearch.textField !== '' && activeSearch.searchBy === '1' && (
        <LastNameTable search={activeSearch.textField} page={page} setPage={setPage} />
      )}
      {activeSearch.textField !== '' && activeSearch.searchBy === '2' && (
        <LastFirstNameTable search={activeSearch.textField} page={page} setPage={setPage} />
      )}
      {activeSearch.textField !== '' && activeSearch.searchBy === '3' && (
        <PinTable search={activeSearch.textField} page={page} setPage={setPage} />
      )}
      {activeSearch.textField !== '' && activeSearch.searchBy === '4' && (
        <CaseNumberTable search={activeSearch.textField} page={page} setPage={setPage} />
      )}
    </main>
  );
};
