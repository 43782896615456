// External
import { FormControl } from '@mui/material';
import { lazy } from 'react';
import 'react-phone-number-input/style.css';

// Local
import './PhoneInput.css';

// Lazy load to reduce bundle size
const ReactPhoneInput = lazy(
  () => import(/* webpackChunkName: "phone-input-component" */ 'react-phone-number-input')
);

interface ComponentProps {
  label: string;
  value: string;
  placeholder: string;
  required: boolean;
  onChange: any;
  error: boolean;
  autoComplete?: string;
}

export const PhoneInput = ({
  label,
  placeholder,
  value,
  onChange,
  required,
  error,
  autoComplete,
}: ComponentProps) => {
  return (
    <FormControl variant="outlined" fullWidth size="small" className="mb-2">
      <label htmlFor="Phone-Number-input" className="mb-1 inline-block font-semibold">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <ReactPhoneInput
        autoComplete={autoComplete}
        id="Phone-Number-input"
        defaultCountry="US"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={error ? 'phone-error' : ''}
        required={required}
      />
    </FormControl>
  );
};
