// External
import { TableCell, TableRow } from '@mui/material';

// Project
import { ExternalLink } from 'src/components/Link';
import { formatDate } from 'src/lib/date';

// Feature
import { IEnforcement } from '../types';

interface LicenseSuspensionRowProps {
  data: IEnforcement;
  userType: string;
  isFlagOn: boolean;
}

export const LicenseSuspensionTableRow = ({
  data,
  userType,
  isFlagOn,
}: LicenseSuspensionRowProps) => {
  if (userType === 'CP4D') return null;
  if (isFlagOn === false) return null;

  let date = undefined;
  if (data.notice_referral_date > 0) {
    date = data.notice_referral_date;
  } else if (data.notice_cert_date > 0) {
    date = data.notice_cert_date;
  } else if (data.release_date > 0) {
    date = data.release_date;
  }

  if (date === undefined) return null;

  // NOTE: only one date should be present at any time
  const ReferralDateMsg =
    'Your name was submitted to various licensing agencies for suspension or denial of your ' +
    'licenses until you have satisfied your unpaid support balance with the CSEA. Although ' +
    'this case may not be eligible, this action is shown on all your cases to provide you ' +
    'with notice that it was taken.';

  const CertDateMsg =
    'Your name was identified for license suspension because of your unpaid support obligation ' +
    'balance. Although this case may not be eligible, this action is shown on all your cases ' +
    'to provide you with notice that it was taken.';

  const ReleaseDateMsg = 'We have discontinued action to suspense your license.';

  return (
    <>
      <TableRow className="[&>td]:border-b-0">
        <TableCell>
          <ExternalLink
            text="License Suspension"
            href="https://ag.hawaii.gov/csea/enforcement/#LSP"
          />
        </TableCell>
        <TableCell>{formatDate(date, 'YYYYMMDD', 'MMMM D, YYYY')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="border-b-0 pl-4 pt-0" colSpan={3}>
          {data.notice_referral_date > 0 && ReferralDateMsg}
          {data.notice_cert_date > 0 && CertDateMsg}
          {data.release_date > 0 && ReleaseDateMsg}
        </TableCell>
      </TableRow>
    </>
  );
};
