// External
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';

// Project
import './index.css';
import theme from 'src/themes/mui';
import App from './routes';
import { Toastify } from './components/Toast';
import { redirectOnDeployedRefresh } from './utils/navigation';
import { queryClient } from './lib/cache';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Environment
if (process.env.REACT_APP_ENV === 'dev') {
  console.log(`%c${process.env.REACT_APP_ENV} environment`, 'color: green; font-size: 15px');
  console.log(`%cbuild: ${process.env.REACT_APP_BUILD}`, 'color: green; font-size: 15px');
} else {
  console.log(`%c${process.env.REACT_APP_ENV} environment`, 'color: red;');
  console.log(`%cbuild: ${process.env.REACT_APP_BUILD}`, 'color: red;');
}

// MSW
// Comment out when not in use
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('src/test/server/browser');
//   worker.start();
// }

// S3 redirect on browser refresh
redirectOnDeployedRefresh();

if (process.env.REACT_APP_ENV === 'prod') {
  // Sentry
  Sentry.init({
    dsn: 'https://67bf0a712af87cdc929265bde0768aba@o4504283370291200.ingest.sentry.io/4505830215581696',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['https://ag-csea.hawaii.gov/'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of the transactions
  });

  // Disable console in production to prevent information overload
  console.log = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    {/* Needed for tailwindcss */}
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            {process.env.REACT_APP_ENV === 'dev' && process.env.REACT_APP_BUILD === 'local' && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <CssBaseline />
            <Toastify />
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
