import { Autocomplete, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const LessThan5CasesNav = ({ cases }) => {
  const { caseId } = useParams();
  return (
    <nav className="my-6">
      {cases.map((c) => {
        return (
          <Link
            to={`${c.case_number}/payments`}
            className="no-underline"
            key={`nav-${c.case_number}`}
          >
            <Button
              variant="outlined"
              className="mr-4 text-2xl font-bold"
              size="large"
              color={caseId === String(c.case_number) ? 'primary' : 'neutral'}
            >
              Case {c.case_number}
            </Button>
          </Link>
        );
      })}
    </nav>
  );
};

const MoreThan4CasesNav = ({ cases }) => {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState<string | null>(caseId);
  const [inputValue, setInputValue] = useState(caseId);

  const casesList = cases.map((c) => String(c.case_number));
  return (
    <div className="flex items-center gap-2">
      <span className="text-2xl font-bold text-primary">Case</span>
      <Autocomplete
        value={value}
        onChange={(
          event: React.SyntheticEvent,
          value: string | null,
          reason: string,
          details?: any
        ) => {
          if (reason === 'clear') {
            setInputValue('');
            return;
          }
          setValue(value);
          navigate(`${value}/payments`);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disablePortal
        autoHighlight
        id="case-id-dropdown"
        options={casesList}
        sx={{ width: 200 }}
        size="small"
        defaultValue={casesList[0]}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, 'aria-label': 'case navigation' }}
          />
        )}
      />
    </div>
  );
};

export const CaseNavBar = ({ cases }) => {
  if (!cases) {
    return null;
  }

  if (cases.length < 5) {
    return <LessThan5CasesNav cases={cases} />;
  }

  return <MoreThan4CasesNav cases={cases} />;
};
