// External
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

// Project
import { ExternalLink } from 'src/components/Link';
import { formatDate } from 'src/lib/date';

// Feature
import { Amount, LicenseSuspensionTableRow, OiwTableRow } from './index';
import { IEnforcement } from '../types';

interface EnforcementsTableProps {
  data: IEnforcement;
  userType: string;
  isFlagOn: boolean;
}

export const EnforcementsTable = ({ data, userType, isFlagOn }: EnforcementsTableProps) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-64">Enforcement Actions</TableCell>
            <TableCell>Date Submitted or Updated</TableCell>
            {userType === 'NCP4D' && <TableCell>Last Balance Report</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.fed_date > '0' && (
            <TableRow>
              <TableCell>
                <ExternalLink
                  text="Federal Offset"
                  href="https://ag.hawaii.gov/csea/enforcement/#FTROP"
                />
              </TableCell>
              <TableCell>{formatDate(data.fed_date, 'YYYYMMDD', 'MMMM D, YYYY')}</TableCell>
              {userType === 'NCP4D' && (
                <TableCell>
                  <Amount value={data.fed_amt} />
                </TableCell>
              )}
            </TableRow>
          )}
          {data.st_date > '0' && (
            <TableRow>
              <TableCell>
                <ExternalLink
                  text="State Tax Refund Setoff"
                  href="https://ag.hawaii.gov/csea/enforcement/#STRSP"
                />
              </TableCell>
              <TableCell>{formatDate(data.st_date, 'YYYYMMDD', 'MMMM D, YYYY')}</TableCell>
              {userType === 'NCP4D' && (
                <TableCell>
                  <Amount value={data.st_amt} />
                </TableCell>
              )}
            </TableRow>
          )}
          {userType === 'NCP4D' && data.cr_date > '0' && (
            <>
              <TableRow className="[&>td]:border-b-0">
                <TableCell>
                  <ExternalLink
                    text="Credit Bureau Reporting"
                    href="https://ag.hawaii.gov/csea/enforcement/#CBR"
                  />
                </TableCell>
                <TableCell>{formatDate(data.cr_date, 'YYYYMMDD', 'MMMM D, YYYY')}</TableCell>
                <TableCell>
                  <Amount value={data.cr_amt} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="pl-4 pt-0" colSpan={3}>
                  The amount reported to the credit bureau is the total DELINQUENT amount you owe on
                  all your IV-D cases.
                </TableCell>
              </TableRow>
            </>
          )}
          <OiwTableRow data={data} userType={userType} />
          <LicenseSuspensionTableRow data={data} userType={userType} isFlagOn={isFlagOn} />
        </TableBody>
      </Table>
    </div>
  );
};
