// Project
import { useHicData } from 'src/hooks';

export const useSettingByOption = (option: string) => {
  const queryParameters = {
    option: option,
  };

  const response = useHicData<any>('/settings', queryParameters);
  console.log('useSettingByOption', response);
  return response;
};
