// External
import { NavLink, NavLinkProps, useParams } from 'react-router-dom';
import { Tab, Tabs, TabProps, ButtonBaseProps } from '@mui/material';

// Project
import PATHS from 'src/config/paths';
import { PRIMARY_COLOR } from 'src/themes/global-constants';
import { doesALocalAppUserExist } from 'src/utils/localStorage';

// Component
// NOTE: disabling modify menu until new requirements are refined
// import { ModifyMenu } from './ModifyMenu';
import { UnlockMenu } from './UnlockMenu';

type TabNavLinkProps = TabProps & NavLinkProps & ButtonBaseProps;

const TabNavLink: React.FC<TabNavLinkProps> = (props) => <Tab {...props} />;

const activeStyle = {
  backgroundColor: 'white',
  color: PRIMARY_COLOR,
  opacity: 1,
  '&:hover': {
    backgroundColor: 'white',
  },
};

const inactiveStyle = {
  color: 'white',
  opacity: 1,
};

const UnauthenticatedTabs = () => {
  return (
    <TabNavLink
      label="Sign In"
      to={PATHS.signIn('customer')}
      LinkComponent={NavLink}
      style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
    />
  );
};

const AgencyTabs = () => {
  return (
    <>
      <TabNavLink
        label="Search Accounts"
        to={PATHS.agencySearch}
        LinkComponent={NavLink}
        style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
      />
      {/* <TabNavLink
        label="Form Report"
        to={PATHS.formReport}
        LinkComponent={NavLink}
        style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
      /> */}
      {/* <ModifyMenu activeStyle={activeStyle} inactiveStyle={inactiveStyle} /> */}
      <UnlockMenu activeStyle={activeStyle} inactiveStyle={inactiveStyle} />
    </>
  );
};

const CustomerTabs = () => {
  return (
    <TabNavLink
      label="My Cases"
      to={PATHS.customerCases}
      LinkComponent={NavLink}
      style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
    />
  );
};

export const NavTabs: React.FC = () => {
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const localAppUserExist = doesALocalAppUserExist();
  let navigation = undefined;

  if (!localAppUserExist) {
    navigation = <UnauthenticatedTabs />;
  } else if (isAppUserAgency) {
    navigation = <AgencyTabs />;
  } else {
    navigation = <CustomerTabs />;
  }

  return (
    <Tabs value={false} aria-label="main navigation bar">
      {navigation}
    </Tabs>
  );
};
