// External
import { Text, Page, View, Document, usePDF } from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';
import { useUserInfo } from 'src/hooks';
import { PageNumber } from 'src/components/Pdf';

// Feature
import { useBalances } from '../api';
import { useNcpFilteredPayments } from './useNcpFilteredPayments';
import {
  BalancesTable,
  PaymentsPendingTable,
  PaymentsReceivedTable,
} from '../components/NcpPaymentReport';

const NcpPaymentHistoryDocument = ({ user, userType, caseId, received, pending, balances }) => {
  const date = new Date();
  const dateFormatted = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const isNot4D = !userType?.includes('4D');

  const {
    mb_1,
    border_b,
    border_style_dashed,
    mt_3,
    mb_3,
    flex,
    flex_row,
    underline,
    justify_center,
    font_bold,
    text_lg,
    text_xl,
  } = pdfStyles;

  return (
    <Document>
      <Page size="LETTER" style={pdfStyles.pageDefault}>
        <Text style={mb_1}>Non-Custodial Parent: {`${user.first_name} ${user.last_name}`}</Text>
        <Text style={mb_1}>
          {isNot4D && 'Non-'}IV-D Case ID: {caseId}
        </Text>
        <Text style={mb_1}>Email: {user.email ? user.email : 'No linked user account'}</Text>
        <Text style={mb_1}>
          Date Requested: {formatDate(dateFormatted, 'MM/DD/YYYY', 'MMMM D, YYYY')}
        </Text>
        <View style={[border_b, border_style_dashed, mt_3, mb_3]} />
        <View style={[flex, flex_row, mt_3, mb_3]}>
          <Text>
            <Text style={[font_bold, text_lg, underline]}>FOR INFORMATIONAL PURPOSES ONLY</Text>
            <Text style={[text_lg]}>
              . Any and all information on this statement is provided for informational purposes
              only and is not to be relied upon as an official record.
            </Text>
          </Text>
        </View>
        <View style={[flex, flex_row, justify_center, mt_3, mb_3]}>
          <Text style={[font_bold, text_xl]}>PAYMENT HISTORY</Text>
        </View>
        <PaymentsPendingTable payments={pending} />
        <View style={mt_3}>
          <PaymentsReceivedTable payments={received} />
        </View>
        <View style={mt_3}>
          <BalancesTable balances={balances} />
        </View>
        <PageNumber />
      </Page>
    </Document>
  );
};

export const useNcpPrintPaymentHistory = (caseId: string, pin: string, userType: string) => {
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const location = useLocation();
  const path = location.pathname;
  let isOnPaymentsReceivedPage = path.split('/').pop().includes('received');
  let isOnPaymentsPendingPage = path.split('/').pop().includes('pending');
  let isOnBalancesPage = path.split('/').pop().includes('balances');

  const {
    pendingPayments: pending,
    receivedPayments: received,
    isLoading: isLoadingPayments,
  } = useNcpFilteredPayments(caseId, pin, isAppUserAgency);

  const { data: balances, isLoading: isLoadingBalance } = useBalances(caseId, pin, isAppUserAgency);
  const { data: user, isLoading: isLoadingUser } = useUserInfo(pin, isAppUserAgency);

  const isDataDefined = user === undefined || balances === undefined;

  const [instance, update] = usePDF({
    document: isDataDefined ? null : (
      <NcpPaymentHistoryDocument
        user={user}
        userType={userType}
        caseId={caseId}
        received={received}
        pending={pending}
        balances={balances}
      />
    ),
  });

  useEffect(() => {
    update(
      isDataDefined ? null : (
        <NcpPaymentHistoryDocument
          user={user}
          userType={userType}
          caseId={caseId}
          received={received}
          pending={pending}
          balances={balances}
        />
      )
    );
  }, [
    isLoadingPayments,
    isLoadingBalance,
    isLoadingUser,
    update,
    user,
    caseId,
    received,
    pending,
    balances,
    isDataDefined,
    userType,
  ]);

  let result = {
    displayNcpPaymentPrintButton:
      isOnBalancesPage || isOnPaymentsPendingPage || isOnPaymentsReceivedPage,
    data: {
      href: instance.url,
      download: `ncp-payment-history.pdf`,
    },
  };
  console.log('useNcpPrintPaymentHistory', result);
  return result;
};
