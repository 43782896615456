// External
import { useHicData } from 'src/hooks';

// Features
import { IMaxForms } from '../types';

export const useMaxForms = (option: string) => {
  const queryParameters = {
    option: option,
  };

  const results = useHicData<IMaxForms>('/settings', queryParameters);
  console.log('useMaxForms', results);
  return results;
};
