// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { trimExtraSpaces } from 'src/utils/stringFormatter';

// Feature
import { AmountCell } from '../../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  amountWidth: 100,
  amountRightPadding: 10,
  dateWidth: 130,
  paidWidth: 220,
  totalWidth: 350,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={{ width: tableProp.dateWidth }}>Issued Date</Text>
    <Text style={{ width: tableProp.paidWidth }}>Paid To</Text>
    <Text style={{ width: tableProp.amountWidth }}>Amount</Text>
  </View>
);

const TableBody = ({ payments }) => {
  const otherDisbInfo = payments[0].other_disb_info;

  if (otherDisbInfo.length > 1) {
    return (
      <View>
        {otherDisbInfo.slice(0, -1).map((other_disb_info, index) => (
          <View style={[flex_row, p_2, pl_3, border_t]} key={`payment-${index}`}>
            <Text style={{ width: tableProp.dateWidth }}>
              {trimExtraSpaces(other_disb_info.issued_date)}
            </Text>
            <Text style={{ width: tableProp.paidWidth }}>
              {trimExtraSpaces(other_disb_info.paid_to)}
            </Text>
            <AmountCell
              width={tableProp.amountWidth}
              amount={other_disb_info.amount}
              paddingRight={tableProp.amountRightPadding}
            />
          </View>
        ))}
      </View>
    );
  }

  return (
    // extra View is necessary for flex
    <View>
      <View style={[flex_row, p_2, pl_3, border_t]}>
        <Text style={{ width: tableProp.dateWidth }}>--</Text>
        <Text style={{ width: tableProp.paidWidth }}>--</Text>
        <AmountCell
          width={tableProp.amountWidth}
          amount={0}
          paddingRight={tableProp.amountRightPadding}
        />
      </View>
    </View>
  );
};

const TableFooter = ({ payments }) => {
  return (
    <View style={[border_t, p_2, pl_3, flex_row]}>
      <Text style={{ width: tableProp.totalWidth }}>
        {payments[0].other_disb_info.slice(-1)[0].issued_date}
      </Text>
      <AmountCell
        paddingRight={tableProp.amountRightPadding}
        amount={payments[0].other_disb_info.slice(-1)[0].amount}
        width={tableProp.amountWidth}
      />
    </View>
  );
};

export const OtherDisburseTable = ({ payments }) => {
  if (payments[0].other_disb_info === null) {
    return (
      <>
        <Text style={[text_m, pb_4, font_bold]}>
          Other Disbursements Made During the Statement Period
        </Text>
        <TableHeader />
        <View>
          <View style={[border_t, p_2, pl_3, flex_row]} key={`payment-${0}`}>
            <Text style={{ width: tableProp.dateWidth }}>--</Text>
            <Text style={{ width: tableProp.paidWidth }}>--</Text>
            <Text
              style={{
                width: tableProp.amountWidth,
                paddingRight: tableProp.amountRightPadding,
              }}
            >
              $ 0.00
            </Text>
          </View>
          <View style={[border_t, p_2, pl_3, flex_row]}>
            <Text style={{ width: tableProp.totalWidth }}>Total Disbursed</Text>
            <Text
              style={{
                width: tableProp.amountWidth,
                paddingRight: tableProp.amountRightPadding,
              }}
            >
              $ 0.00
            </Text>
          </View>
        </View>
      </>
    );
  }

  return (
    <>
      <Text style={[text_m, pb_4, font_bold]}>
        Other Disbursements Made During the Statement Period
      </Text>
      <TableHeader />
      <TableBody payments={payments} />
      <TableFooter payments={payments} />
    </>
  );
};
