// External
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';

// Feature
import { getMetaData } from '../../utils/column';

export const getTableColumns = (search: string) => {
  const CP_PIN_COLUMN_NAME = 'cp_pin';
  const NCP_PIN_COLUMN_NAME = 'ncp_pin';
  const CP_NAME_COLUMN_NAME = 'cp_name';
  const NCP_NAME_COLUMN_NAME = 'ncp_name';

  const searchedParentIsNcp = (meta: MUIDataTableMeta) =>
    +search === getMetaData(NCP_PIN_COLUMN_NAME, columns, meta);
  const LinkToCurrentParent = (meta: MUIDataTableMeta) => {
    const nameValue = getMetaData(
      searchedParentIsNcp(meta) ? NCP_NAME_COLUMN_NAME : CP_NAME_COLUMN_NAME,
      columns,
      meta
    );
    if (search === '999999998') {
      return <span>{nameValue}</span>;
    }
    return (
      <Link className="text-primary hover:text-primary" to={`/agency/${search}/cases`}>
        {nameValue}
      </Link>
    );
  };
  const LinkToOtherParent = (meta: MUIDataTableMeta) => {
    const otherParentPin = getMetaData(
      searchedParentIsNcp(meta) ? CP_PIN_COLUMN_NAME : NCP_PIN_COLUMN_NAME,
      columns,
      meta
    );
    return (
      <Link className="text-primary hover:text-primary" to={`/agency/${otherParentPin}/cases`}>
        {getMetaData(
          searchedParentIsNcp(meta) ? CP_NAME_COLUMN_NAME : NCP_NAME_COLUMN_NAME,
          columns,
          meta
        )}
      </Link>
    );
  };
  const getRole = (meta: MUIDataTableMeta) =>
    searchedParentIsNcp(meta) ? 'Non-Custodial Parent' : 'Custodial Parent';

  const columns: MUIDataTableColumn[] = [
    {
      name: '',
      label: 'PIN',
      options: {
        customBodyRender: () => search,
        sort: false,
      },
    },
    {
      name: 'cp_name',
      label: 'Name',
      options: {
        customBodyRender: (_, meta: MUIDataTableMeta) => LinkToCurrentParent(meta),
        sort: false,
      },
    },
    {
      name: 'case_id',
      label: 'Case ID',
      options: {
        sort: false,
      },
    },
    {
      name: '',
      label: 'Role',
      options: {
        customBodyRender: (_, meta: MUIDataTableMeta) => getRole(meta),
        sort: false,
      },
    },
    {
      name: 'cp_pin',
      label: 'CP PIN',
      options: {
        display: false,
        sort: false,
      },
    },
    {
      name: 'ncp_pin',
      label: 'NCP PIN',
      options: {
        display: false,
        sort: false,
      },
    },
    {
      name: 'ncp_name',
      label: 'Other Party',
      options: {
        customBodyRender: (_, meta: MUIDataTableMeta) => LinkToOtherParent(meta),
        sort: false,
      },
    },
    {
      name: 'ivd_flag',
      label: 'IV-D Status',
      options: {
        sort: false,
      },
    },
  ];

  return columns;
};
