// External
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Project
import { ExternalLink } from 'src/components/Link';
import PATHS from 'src/config/paths';
import { CSEA_CUSTOMER_SERVICE_URL } from 'src/config';

const NoPinAccessCode = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className="text-center text-2xl">New Account</h1>
      <p>
        Your CSEA Personal Identification Number (PIN) and Access Code are required to create an
        account. To request your PIN and Access Code, please contact&nbsp;
        <ExternalLink text="Customer Service" href={CSEA_CUSTOMER_SERVICE_URL} />.
      </p>
      <div className="mb-6 flex justify-between gap-5">
        <Button variant="outlined" onClick={() => navigate(PATHS.pinCheck)} className="w-1/2">
          Previous
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate(PATHS.signIn('customer'))}
          className="w-1/2"
        >
          Go to Sign In
        </Button>
      </div>
    </>
  );
};

export default NoPinAccessCode;
