// External
import { useContext, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';

// Feature
import { CognitoContext } from '../providers';
import { SuccessModal } from '../modals';

const VerifySms = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { cognitoObj } = useContext(CognitoContext);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [showResendConfirm, setResendConfirm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [username, setUsername] = useState(cognitoObj?.username);

  useEffect(() => {
    // if username is present on location's state
    // it means user not confirmed and is
    // redirected from sign in page
    const resendSignUpFromSignIn = async () => {
      const state = location.state;
      const email = state?.email;
      if (email) {
        setUsername(email);
        await Auth.resendSignUp(email);
      }
    };
    resendSignUpFromSignIn();
  }, [location.state]);

  const handleConfirm = async (event) => {
    try {
      event.preventDefault();
      await Auth.confirmSignUp(username, code);
      setShowSuccessModal(true);
    } catch (err) {
      console.log('verifySmsError', err);
      setError('Invalid code. Please try again');
    }
  };

  const handleOnChange = (e) => {
    setCode(e.target.value);
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(username);
      setResendConfirm(true);
    } catch (err) {
      console.log('resend error', err);
      setError('System error. Try again in a bit.');
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl">Verify Phone Number</h1>
      <p>A verification code has been sent to your phone number. Please enter the code below.</p>
      <form onSubmit={handleConfirm}>
        <TextInput
          name="code"
          label="Code"
          value={code}
          placeholder="Verification Code"
          required
          onChange={handleOnChange}
          error={error !== ''}
        />
        {error && <div className="text-red-600">{error}</div>}
        {showResendConfirm && <p className="text-green-600">Verification code has been resent!</p>}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Confirm
        </Button>
      </form>
      <div className="mt-3 flex justify-between">
        <Link to={PATHS.signIn('customer')} className="p-2 text-primary no-underline">
          Back to Sign In
        </Link>
        <Button className="p-2 text-base font-normal" onClick={handleResendCode}>
          Resend Code
        </Button>
      </div>
      <SuccessModal
        showModal={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        handleOk={() => navigate(PATHS.signIn('customer'))}
        message="Your account has been created successfully. Please sign in."
      />
    </>
  );
};

export default VerifySms;
