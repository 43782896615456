// External
import React, { useEffect } from 'react';
import {
  useParams,
  Outlet,
  NavLink,
  useLocation,
  NavLinkProps,
  useNavigate,
} from 'react-router-dom';
import { Tabs, Tab, TabProps, ButtonBaseProps, Button } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

// Project
import { navigationUrlParser } from 'src/utils/navigation';
import { PRIMARY_COLOR } from 'src/themes/global-constants';
import { Spinner } from 'src/components/Spinner';
import { CenterLayout } from 'src/layout';
import { useUserType, useSettingByOption } from 'src/hooks';

// EXCEPTION being made for printing cp statement & payments. Refer to hook code
import {
  useCpPrintStatement,
  useCpPrintPaymentHistory,
  useNcpPrintPaymentHistory,
  usePrintRecoupment,
  useBillPaymentInfo,
} from 'src/features/payments/hooks';
import { PaymentInfo } from 'src/features/payments/components/NcpBillPaymentInfo';

type TabNavLinkProps = TabProps & NavLinkProps & ButtonBaseProps;
const TabNavLink: React.FC<TabNavLinkProps> = (props) => <Tab {...props} />;

const activeStyle = {
  color: PRIMARY_COLOR,
};

const inactiveStyle = {};

export const CaseDetailsLayout: React.FC = () => {
  const navigate = useNavigate();
  const { appUser, userIdentifier, caseId } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const location = useLocation();
  const path = location.pathname;
  const { caseMainTab, caseSubtab } = navigationUrlParser(path);
  const {
    data,
    isLoading: userIsLoading,
    error: userError,
  } = useUserType(caseId, userIdentifier, isAppUserAgency);
  const userType = data?.user_type;
  // CP Print Statement
  const {
    displayCpStatementPrintButton,
    disableCpStatementPrintButton,
    data: printCpStatementData,
  } = useCpPrintStatement(caseId, userType);
  // CP Print Payments
  const { displayCpPaymentPrintButton, data: printCpPaymentData } = useCpPrintPaymentHistory(
    caseId,
    userIdentifier,
    userType
  );
  // NCP Print Payments
  const { displayNcpPaymentPrintButton, data: printNcpPaymentData } = useNcpPrintPaymentHistory(
    caseId,
    userIdentifier,
    userType
  );
  // Print Recoupment
  const { displayRecoupmentPrintButton, data: printNcpRecoupmentData } = usePrintRecoupment(
    caseId,
    userIdentifier
  );
  // Print Bill Statement
  const {
    displayBillPaymentInfo,
    data: printBillPaymentInfoData,
    report: printBillPaymentInfoReport,
  } = useBillPaymentInfo(caseId, userIdentifier);

  // case status flag
  const { data: statusFlag } = useSettingByOption('case-status');

  const NCP_DEFAULT_PATH = 'payments/pending';
  const CP_DEFAULT_PATH = 'payments/applied';

  const PAYMENT_INFO_PATH = 'payment-info';

  useEffect(() => {
    switch (userType) {
      case undefined:
        break;
      case 'CP':
      case 'CP4D':
        if (caseMainTab === 'payments' && !caseSubtab) {
          navigate(CP_DEFAULT_PATH, { replace: true });
        }
        break;
      case 'NCP':
      case 'NCP4D':
        if (caseMainTab === 'payments' && !caseSubtab) {
          navigate(NCP_DEFAULT_PATH, { replace: true });
        }
        break;
      default:
        throw new Error("User type doesn't exist");
    }
    // Added path to trigger re-render when clicking case number navigation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType, path]);

  if (userIsLoading) {
    return (
      <CenterLayout>
        <Spinner />
      </CenterLayout>
    );
  }

  if (userError) return <span>Error: {userError.response?.data.message}</span>;

  if (caseSubtab === PAYMENT_INFO_PATH) {
    if (!printBillPaymentInfoData) {
      return (
        <CenterLayout>
          <Spinner />
        </CenterLayout>
      );
    }
    return (
      <PaymentInfo
        caseId={caseId}
        data={printBillPaymentInfoData}
        report={printBillPaymentInfoReport}
      />
    );
  }

  const getPaymentsNavLink = (user) => {
    switch (user) {
      case 'NCP':
      case 'NCP4D':
        return (
          <TabNavLink
            label="Payments"
            value="payments"
            to={NCP_DEFAULT_PATH}
            LinkComponent={NavLink}
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          />
        );
      case 'CP':
      case 'CP4D':
        return (
          <TabNavLink
            label="Payments"
            value="payments"
            to={CP_DEFAULT_PATH}
            LinkComponent={NavLink}
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          />
        );
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <Tabs value={caseMainTab} aria-label="case nav bar">
          {/* NOTE: Must use function. MUI Tabs won't get the value on time to style if component  */}
          {getPaymentsNavLink(userType)}
          {userType.includes('4D') && (
            <TabNavLink
              label="Enforcement"
              value="enforcements"
              to="enforcements"
              LinkComponent={NavLink}
              style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
            />
          )}
          {userType.includes('4D') && (
            <TabNavLink
              label="Hearings"
              value="hearings"
              to="hearings"
              LinkComponent={NavLink}
              style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
            />
          )}
          <TabNavLink
            label="Forms"
            value="forms"
            to="forms/request-mail"
            LinkComponent={NavLink}
            style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
          />
          {userType.includes('4D') && statusFlag?.result === 'enable' && (
            <TabNavLink
              label="Status"
              value="status"
              to="status"
              LinkComponent={NavLink}
              style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
            />
          )}
        </Tabs>
        {displayCpStatementPrintButton && (
          <div className="mb-1 flex items-center">
            <Button
              variant="contained"
              startIcon={<PrintOutlinedIcon />}
              href={printCpStatementData.href}
              download={printCpStatementData.download}
              disabled={disableCpStatementPrintButton}
            >
              Print CP Statement
            </Button>
          </div>
        )}
        {displayCpPaymentPrintButton && userType.startsWith('CP') && (
          <div className="mb-1 flex items-center">
            <Button
              variant="contained"
              startIcon={<PrintOutlinedIcon />}
              href={printCpPaymentData.href}
              download={printCpPaymentData.download}
            >
              Print Payment History
            </Button>
          </div>
        )}
        {displayNcpPaymentPrintButton && userType.startsWith('NCP') && (
          <div className="mb-1 flex items-center">
            <Button
              variant="contained"
              startIcon={<PrintOutlinedIcon />}
              href={printNcpPaymentData.href}
              download={printNcpPaymentData.download}
            >
              Print Payment History
            </Button>
          </div>
        )}
        {displayRecoupmentPrintButton && (
          <div className="mb-1 flex items-center">
            <Button
              variant="contained"
              startIcon={<PrintOutlinedIcon />}
              href={printNcpRecoupmentData.href}
              download={printNcpRecoupmentData.download}
            >
              Print Voucher and Mail Check
            </Button>
          </div>
        )}
        {displayBillPaymentInfo && (
          <div className="mb-1 flex items-center">
            <Button variant="contained" href={PAYMENT_INFO_PATH}>
              Payment Info
            </Button>
          </div>
        )}
      </div>
      <hr className="m-0 mb-8" />
      <div className="flex min-h-[50vh]">
        <Outlet />
      </div>
    </div>
  );
};
