// External
import { useState } from 'react';
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  required: boolean;
  onChange: any;
  error: boolean;
  className?;
  autoComplete?: string;
}

export const PasswordInput = ({
  name,
  label,
  placeholder,
  value,
  required,
  onChange,
  error,
  className,
  autoComplete,
}: Props) => {
  let id = `${label}-input`.replace(' ', '-');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" fullWidth size="small" className={className}>
      <label htmlFor={id} className="mb-1 inline-block font-semibold">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <OutlinedInput
        name={name}
        required={required}
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        error={error}
        placeholder={placeholder}
        label={null}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
