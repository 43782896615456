// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import BillStatementImg from '../../assets/BillStatement.webp';

export const BillStatement = () => {
  return (
    <Accordion summary="How do I view my Bill Statement?">
      <p className="mt-0">
        If you received a Bill Statement in the mail, information is available in the Bill Statement
        tab.
      </p>
      <LazyLoadImage src={BillStatementImg} alt="bill statement" height={500} />
    </Accordion>
  );
};
