// External
import { memo } from 'react';
import validator from 'validator';

// Project
import { Spinner } from 'src/components/Spinner';
import { CustomMuiDataTable } from 'src/components/Table';

// Features
import { useByLastName } from '../../api/searchBy';
import { SearchResultHeader } from '../SearchResultHeader';
import { getTableColumns } from './tableColumns';
import { getTableOptions } from './tableOptions';

const Component = ({ search, page, setPage }) => {
  const { data, isLoading, error, isPreviousData } = useByLastName(search, page);

  const isSearchValid =
    !validator.isNumeric(search.trim()) && validator.isLength(search, { min: 1 });

  if (!isSearchValid || error?.response?.data?.message === 'Invalid value for last-name') {
    return (
      <>
        <SearchResultHeader />
        <div>No results found.</div>
      </>
    );
  }

  if (isLoading)
    return (
      <div className="mt-12 flex justify-center">
        <Spinner />
      </div>
    );

  if (error)
    return (
      <>
        <SearchResultHeader />
        <span>Error: {error.response?.data.message}</span>
      </>
    );

  const results = data.message;
  if (results === null)
    return (
      <>
        <SearchResultHeader />
        <div>No results found.</div>
      </>
    );

  const totalPages = data['total-pages']['total-pages'] as number;
  return (
    <>
      <SearchResultHeader />
      <CustomMuiDataTable
        data={results}
        options={getTableOptions(totalPages, page, setPage)}
        columns={getTableColumns()}
        isFetchingNextData={isPreviousData}
      />
    </>
  );
};

export const LastNameTable = memo(Component);
