// Project
import { useCaseData } from 'src/hooks';

// Feature
import { ILockedAccounts } from '../types';

export const useLinkingLockedAccounts = (filter: string, pageNumber: number) => {
  const queryParameters = {
    keyword: filter,
    'page-number': pageNumber + 1,
  };

  const results = useCaseData<ILockedAccounts>('/blocked-users', queryParameters);
  console.log('useLockedAccounts', results);
  return results;
};
