// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import DisbursementsImg from '../../assets/Disbursements.webp';

export const Disbursements = () => {
  return (
    <Accordion summary="How do I view my Disbursements?">
      <p className="mt-0">
        Disbursements issued on behalf of the CP are displayed in the Disbursements tab.
      </p>
      <LazyLoadImage src={DisbursementsImg} alt="disbursements" height={350} />
    </Accordion>
  );
};
