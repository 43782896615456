// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';

export const AddressBox = () => {
  const { flex_row, font_bold, mt_3, mb_6, w_50, pl_8 } = pdfStyles;

  return (
    <View style={[mb_6, mt_3, flex_row]}>
      <View style={[pl_8, w_50]}>
        <Text style={font_bold}>Send To</Text>
        <Text>Child Support Enforcement Agency</Text>
        <Text>P.O. Box 1860</Text>
        <Text>Honolulu, HI&nbsp; 96805-1860</Text>
      </View>
    </View>
  );
};
