// External
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

// Project
import { IHookError } from 'src/types';

// Features
import { fetchData, prefetchNextPage } from './fetch';
import { splitNameToFirstAndLastName } from '../utils/name';

// Search API expects 1-indexed page number

interface ICaseMessage {
  message: [
    {
      ncp_name: string;
      ncp_pin: number;
      cp_name: string;
      cp_pin: number;
      case_id: number;
      ivd_flag: string;
    }
  ];
}

interface ICustomerMessage {
  message: [
    {
      last_name: string;
      first_name: string;
      caller_pin: number;
    }
  ];
}

const getQueryParameters = (queryKey, payload) => {
  return {
    queryKey,
    queryFn: async () => fetchData(payload),
    keepPreviousData: true,
  };
};

export const useByLastName = (search: string, page: number) => {
  search = encodeURIComponent(search.trim());
  const queryClient = useQueryClient();
  const payload = {
    'last-name': search,
    'page-number': page + 1,
  };

  const result = useQuery<ICustomerMessage, IHookError>(
    getQueryParameters(['lastname', search, page], payload)
  );

  useEffect(() => {
    if (result.data !== undefined) {
      const nextPayload = {
        'last-name': search,
        'page-number': page + 2,
      };
      prefetchNextPage(
        queryClient,
        [search, page + 2],
        page + 1,
        result.data['total-pages']['total-pages'],
        nextPayload
      );
    }
  }, [page, queryClient, result, search]);

  console.log('useByLastName', result);
  return result;
};

export const useByLastFirstName = (search: string, page: number) => {
  search = search.trim();
  const queryClient = useQueryClient();
  const { firstName, lastName } = splitNameToFirstAndLastName(search);

  const encodedFirstName = encodeURIComponent(firstName);
  const encodedLastName = encodeURIComponent(lastName);

  const payload = {
    'last-name': encodedLastName,
    'first-name': encodedFirstName,
    'page-number': page + 1,
  };

  const result = useQuery<ICustomerMessage, IHookError>(
    getQueryParameters(['fnln', encodedFirstName, encodedLastName, page], payload)
  );

  useEffect(() => {
    if (result.data !== undefined) {
      const nextPayload = {
        'last-name': encodedLastName,
        'first-name': encodedFirstName,
        'page-number': page + 2,
      };

      prefetchNextPage(
        queryClient,
        [encodedFirstName, encodedLastName, page + 2],
        page + 1,
        result.data['total-pages']['total-pages'],
        nextPayload
      );
    }
  }, [queryClient, encodedLastName, encodedFirstName, page, result.data]);

  console.log('useByLastFirstName', result);
  return result;
};

export const useByPin = (search: string, page: number) => {
  search = search.trim();
  const queryClient = useQueryClient();
  const payload = {
    pin: search,
    'page-number': page + 1,
  };

  const result = useQuery<ICaseMessage, IHookError>(
    getQueryParameters(['pin', search, page], payload)
  );

  useEffect(() => {
    if (result.data !== undefined) {
      const nextPayload = {
        pin: search,
        'page-number': page + 2,
      };

      prefetchNextPage(
        queryClient,
        [search, page + 2],
        page + 1,
        result.data['total-pages']['total-pages'],
        nextPayload
      );
    }
  }, [queryClient, page, search, result.data]);

  console.log('useByPin', result);
  return result;
};

export const useByCaseNumber = (search: string, page: number) => {
  search = search.trim();
  const queryClient = useQueryClient();
  const payload = {
    'case-number': search,
    'page-number': page + 1,
  };

  const result = useQuery<ICaseMessage, IHookError>(
    getQueryParameters(['case', search, page], payload)
  );

  useEffect(() => {
    if (result.data !== undefined) {
      const nextPayload = {
        'case-number': search,
        'page-number': page + 2,
      };

      prefetchNextPage(
        queryClient,
        [search, page + 2],
        page + 1,
        result.data['total-pages']['total-pages'],
        nextPayload
      );
    }
  }, [queryClient, page, search, result.data]);

  console.log('useByCaseNumber', result);
  return result;
};
