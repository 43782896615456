// Project
import { useHicData } from 'src/hooks';

// Feature
import { Payment } from '../types';

export const usePayments = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const results = useHicData<Payment[]>('/payment', queryParameters);
  console.log('usePayments', results);
  return results;
};
