// Project
import { useState } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ExternalLink } from 'src/components/Link';

// Project
import PATHS from 'src/config/paths';

// Features
import './TermsConditions.css';

const TermsConditions = () => {
  const navigate = useNavigate();
  const [checkValue, setCheckValue] = useState(false);

  const updateButtonsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckValue(event.target.checked);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (checkValue) {
      navigate(PATHS.createAccount);
    } else {
      navigate(PATHS.signIn('customer'));
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl">Acknowledgement</h1>
      <h2 className="m-0 text-lg font-bold">Terms of Agreement</h2>
      <div className="terms-conditions h-72 w-[510px] overflow-y-scroll pr-1">
        <p>
          This system may contain Government information, which is restricted to authorized users
          ONLY. Unauthorized access, use, misuse, or modification of this computer system or of the
          data contained herein or in transit to/from this system constitutes a violation of Title
          18, United States Code Section 1030, and may subject the individual to Criminal and Civil
          penalties pursuant to Title 26, United State Code, Sections 7213, 7213A (the Taxpayer
          Browsing Protection Act), and 7431. This system and equipment are subject to monitoring to
          ensure proper performance of application security features or procedures. Such monitoring
          may result in the acquisition, recording and analysis of all data being communicated,
          transmitted, processed, or stored in this system by a user. If monitoring reveals possible
          evidence of criminal activity, such evidence may be provided to Law Enforcement Personnel.
          ANYONE USING THIS SYSTEM EXPRESSLY CONSENTS TO SUCH MONITORING.
        </p>
        <p>
          The Child Support Enforcement Agency, State of Hawaii, will use the information provided
          on the website solely for authorized purposes. Furthermore, the information provided on
          this website will be kept confidential. It will be protected against unauthorized access
          and be kept confidential according to the security requirements established by Hawaii
          Revised Statues § 576D-12, the Social Security Act, the Privacy Act of 1974, the Federal
          Information Security Modernization Act of 2014 (FISMA), 42 United States Code (U.S.C.) §
          653(m), 654(26), 42 USC 654a(d)(1)-(5), the U.S. Department of Health and Human Services
          (HHS), and the federal Office of Child Support Enforcement (OCSE).
        </p>
        <h2 className="m-0 text-lg font-bold">ADA Compliance</h2>
        <p>
          The Child Support Enforcement Agency, State of Hawaii, complies with the Americans with
          Disabilities Act and Hawaii Revised Statues §§ 368.15 and 489. If you need a reasonable
          accommodation in the rules, policies, practices, or services to afford you an equal
          opportunity to use this service, please contact{' '}
          <ExternalLink text="CSEA Customer Service" href="https://ag.hawaii.gov/csea/contact/" />.
          Once the Child Support Enforcement Agency, State of Hawaii, receives the request, a
          representative will contact you within 48 hours. The Child Support Enforcement Agency,
          State of Hawaii, will provide all reasonable requests for auxiliary aids, services, or
          accommodations if it will not fundamentally alter the program's nature or service, or it
          will not be an undue financial or administrative burden.
        </p>
      </div>
      <form className="mt-3" onSubmit={handleOnSubmit}>
        <label>
          <input type="checkbox" onChange={updateButtonsValue} className="mr-2 accent-primary" />
          <span>I agree with the Terms and Conditions listed above.</span>
        </label>
        <Button
          variant="contained"
          type="submit"
          disabled={!checkValue}
          className="mt-6 w-full normal-case"
        >
          Next
        </Button>
      </form>
      <Link
        to={PATHS.signIn('customer')}
        className="mt-5 flex justify-center text-primary no-underline"
      >
        Back to Sign In
      </Link>
    </>
  );
};

export default TermsConditions;
