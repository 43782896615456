// Project
import { useHicData } from 'src/hooks';

// Feature
import { IEnforcement } from '../types';

export const useEnforcement = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const response = useHicData<IEnforcement>('/enforcement', queryParameters);
  console.log('useEnforcement', response);
  return response;
};
