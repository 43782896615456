// External
import { MUIDataTableColumn } from 'mui-datatables';
import { Link } from 'react-router-dom';

export const getTableColumns = () => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'last_name',
      label: 'Last Name',
      options: { sort: false },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: { sort: false },
    },
    {
      name: 'caller_pin',
      label: 'PIN',
      options: {
        customBodyRender: (value: string) => {
          return (
            <Link className="text-primary hover:text-primary" to={`/agency/${value}/cases`}>
              {value}
            </Link>
          );
        },
        sort: false,
      },
    },
  ];

  return columns;
};
