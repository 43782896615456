// External
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { LazyLoadImage as ExternalLazyLoadImage } from 'react-lazy-load-image-component';

interface LazyLoadImageProps {
  height: number;
  width?: number;
  src: React.ImgHTMLAttributes<HTMLImageElement>['src'];
  alt: string;
  className?: string;
  imgClassName?: string;
}

export const LazyLoadImage = ({
  height,
  width,
  src,
  alt,
  className,
  imgClassName,
}: LazyLoadImageProps) => {
  const [loading, setLoading] = useState(true);
  const skeletonW = width || height;

  return (
    <div className={className}>
      <Skeleton variant="rectangular" height={loading ? height : 0} width={skeletonW} />
      <ExternalLazyLoadImage
        src={src}
        alt={alt}
        height={loading ? 0 : height}
        width={width}
        onLoad={() => setLoading(false)}
        threshold={height}
        className={imgClassName}
      />
    </div>
  );
};
