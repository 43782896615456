// Types
import { Payment, IBillStatement } from '../types';

const sortByDateRecd = (data: Payment[] | Payment[][]) => {
  // latest to oldest date
  return data.sort((a, b) => {
    if (Array.isArray(a) && Array.isArray(b)) {
      // received
      return b[0].date_recd - a[0].date_recd;
    } else {
      // pending
      return b.date_recd - a.date_recd;
    }
  });
};

export const getFilteredPaymentsReceivedData = (data: Payment[]) => {
  let pending: Payment[] = [];
  let received: { rect_nbr?: Payment[] } = {};

  data.forEach((payment) => {
    // Pending payments
    if (payment.alloc_amt === 0 && payment.alloc_period === 0) {
      pending.push(payment);
      return;
    }
    // Received payments, determine grouping requirements
    if (payment.rect_nbr in received) {
      received[payment.rect_nbr].push(payment);
      sortByDateRecd(received[payment.rect_nbr]);
    } else {
      received[payment.rect_nbr] = [payment];
    }
  });

  let receivedEntries = Object.values(received);

  let sortedReceived = sortByDateRecd(receivedEntries);
  sortByDateRecd(pending);

  const results = {
    pendingPayments: pending,
    receivedPayments: sortedReceived.flat(),
  };
  console.log('getFilteredPaymentsReceivedData', results);
  return results;
};

export const BALANCE_AS_OF_INDICATOR = 'Balance as of';

export const getBillStatementBalanceDue = (data: IBillStatement[]) => {
  const arr = data[0].bill_report.filter((br) => br.description.includes(BALANCE_AS_OF_INDICATOR));
  return arr[arr.length - 1].amount;
};
