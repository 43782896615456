// External
import { useState, useEffect, useContext } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';
import { useLocalAppUser } from 'src/hooks';

// Feature
import { CognitoContext } from '../providers';
import { signOut } from 'src/lib/auth';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { appUser } = useParams();
  const userType = appUser === 'customer' ? 'customer' : 'agency';
  const { setLocalAppUser } = useLocalAppUser();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { updateCognitoObj } = useContext(CognitoContext);
  const [showResendConfirm, setResendConfirm] = useState(false);

  useEffect(() => {
    const sendInitialEmailVerification = async () => {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
        updateCognitoObj(user);
        const emailVerified = user.attributes.email_verified === 'true';
        if (emailVerified) {
          setLocalAppUser(userType);
          navigate(PATHS.linkKeiki);
        } else {
          try {
            Auth.verifyUserAttribute(user, 'email');
          } catch (error) {
            console.log('error', error);
          }
        }
      });
    };
    sendInitialEmailVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerify = async (event) => {
    try {
      event.preventDefault();
      setResendConfirm(false);
      let user = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(user, 'email', code);
      setLocalAppUser(userType);
      navigate(PATHS.linkKeiki);
    } catch (err) {
      console.log('Email Verify Error:', err);
      setError('Invalid code. Please try again');
    }
  };

  const handleResendCode = async () => {
    try {
      Auth.verifyCurrentUserAttribute('email');
      setResendConfirm(true);
    } catch (error) {
      console.log('resend error', error);
      setError('System error. Try again in a bit.');
    }
  };

  const handleOnBackToSignIn = () => {
    signOut();
  };

  return (
    <>
      <h2 className="text-center">Email Verification</h2>
      <p>A verification code has been sent to your email. Please enter the code below.</p>
      <form onSubmit={handleVerify}>
        <TextInput
          name="code"
          label="Code"
          value={code}
          placeholder="Authentication Code"
          required
          onChange={(e) => setCode(e.target.value)}
          error={error !== ''}
        />
        {error && <div className="text-red-600">{error}</div>}
        {showResendConfirm && <p className="text-green-600">Verification code has been resent!</p>}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Verify
        </Button>
      </form>
      <div className="mt-3 flex justify-between">
        <Link
          to={PATHS.signIn('customer')}
          onClick={handleOnBackToSignIn}
          className="p-2 text-primary no-underline"
        >
          Back to Sign In
        </Link>
        <Button className="p-2 text-base font-normal" onClick={handleResendCode}>
          Resend Code
        </Button>
      </div>
    </>
  );
};

export default VerifyEmail;
