// External
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';

interface CheckboxProps {
  checked: boolean;
  onChange: (event: any) => void;
  name: string;
  label: string;
  disableKeyDown?: boolean;
  className?: string;
}

export const Checkbox = ({
  checked,
  onChange,
  name,
  label,
  disableKeyDown = false,
  className,
}: CheckboxProps) => {
  const handleKeyDown = (event) => {
    if (disableKeyDown === false && event.key === 'Enter') {
      event.target.checked = !event.target.checked;
      onChange(event);
    }
  };

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          checked={checked}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          inputProps={{ name: name }}
          className={className}
        />
      }
      label={label}
    />
  );
};
