// External
import { TableCell, TableRow } from '@mui/material';

// Project
import { ExternalLink } from 'src/components/Link';

// Feature
import { Amount } from './index';
import { IEnforcement } from '../types';

interface OiwRowProps {
  data: IEnforcement;
  userType: string;
}

// Oiw = Order for Income Withholding
export const OiwTableRow = ({ data, userType }: OiwRowProps) => {
  // Only CP4D has oiw_order
  if (userType === 'CP4D' && (data.oiw_served === 'N' || data.oiw_order === 'N')) {
    return null;
  }

  if (userType === 'CP4D' || data.oiw_amt > 0) {
    return (
      <TableRow>
        <TableCell>
          <ExternalLink
            text="Order for Income Withholding"
            href="https://ag.hawaii.gov/csea/enforcement/#IW"
          />
        </TableCell>
        {userType === 'CP4D' ? (
          <TableCell>An income withholding has been served for this case.</TableCell>
        ) : (
          <>
            <TableCell></TableCell>
            <TableCell>
              {/* {user is NCP4D} */}
              <Amount value={data.oiw_amt} />
            </TableCell>
          </>
        )}
      </TableRow>
    );
  }

  return null;
};
