// External
import { useState } from 'react';

// Feature
import { RequestMailSubmission } from './RequestMailSubmission';
import { RequestMailConfirmation } from './RequestMailConfirmation';

export const RequestMail = () => {
  const [data, setData] = useState<Array<string>>(null);

  const handleFormSubmit = (params: Array<string>) => {
    setData(params);
  };

  const reset = () => {
    setData(null);
  };

  if (!data) {
    return <RequestMailSubmission submit={handleFormSubmit} />;
  }

  return <RequestMailConfirmation data={data} reset={reset} />;
};
