// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatZipCode } from 'src/utils/address';

const tableProp = {
  // Text column widths
  firstColumnWidth: 300,
  secondColumnWidth: 300,
};

const { flex_row, mb_1, mb_2, text_right, flex_1 } = pdfStyles;

const CityStateZipItem = ({ info }) => {
  return (
    <View style={flex_1}>
      <Text style={[{ minWidth: tableProp.secondColumnWidth }, text_right, mb_1]}>
        {info[0].case_info[0].city +
          ', ' +
          info[0].case_info[0].state +
          ' ' +
          formatZipCode(info[0].case_info[0].zip_code)}
      </Text>
    </View>
  );
};

export const CaseInfo = ({ info }) => {
  if (info[0].case_info === null) {
    return <Text>Case Information cannot be retrieved right now</Text>;
  }

  const isAddress2Blank = info[0].case_info[0].address_line_2 === '';

  return (
    <>
      <View style={flex_row}>
        <Text style={[{ minWidth: tableProp.firstColumnWidth }, mb_1]}>
          Period: {info[0].period_info[0].start_period} - {info[0].period_info[0].end_period}
        </Text>
        <View style={flex_1}>
          <Text style={[{ minWidth: tableProp.secondColumnWidth }, text_right, mb_1]}>
            Mailing Address: {info[0].case_info[0].cp_name}
          </Text>
        </View>
      </View>
      <View style={flex_row}>
        <Text style={[{ minWidth: tableProp.firstColumnWidth }, mb_1]}>
          Case ID: {info[0].case_info[0].case_id.toString()}
        </Text>
        <View style={flex_1}>
          <Text style={[{ minWidth: tableProp.secondColumnWidth }, text_right, mb_1]}>
            {info[0].case_info[0].address_line_1}
          </Text>
        </View>
      </View>
      <View style={flex_row}>
        <Text style={[{ minWidth: tableProp.firstColumnWidth }, mb_1]}>
          Custodial Parent: {info[0].case_info[0].cp_name}
        </Text>
        {/* Decide if city, state, and zip code should be on  3rd or 4th line */}
        {isAddress2Blank ? (
          <CityStateZipItem info={info} />
        ) : (
          <View style={flex_1}>
            <Text style={[{ minWidth: tableProp.secondColumnWidth }, text_right, mb_1]}>
              {info[0].case_info[0].address_line_2}
            </Text>
          </View>
        )}
      </View>
      <View style={flex_row}>
        <Text style={[{ minWidth: tableProp.firstColumnWidth }, mb_2]}>
          Non-Custodial Parent: {info[0].case_info[0].ap_name}
        </Text>
        {!isAddress2Blank && <CityStateZipItem info={info} />}
      </View>
    </>
  );
};
