// Project
import { LazyLoadImage } from 'src/components/Image';
// Feature
import { Accordion } from '../shared/Accordion';
import { UnorderedList } from '../shared/List';
import MyProfileButton from '../../assets/MyProfileButton.webp';
import MyProfilePage from '../../assets/MyProfilePage.webp';
import UpdatePhone from '../../assets/UpdatePhone.webp';
import UpdateEmail from '../../assets/UpdateEmail.webp';
import ChangePassword from '../../assets/ChangePassword.webp';

export const Profile = () => {
  return (
    <Accordion summary="How do I update my Profile information?">
      <p className="mt-0">
        Click on the Profile icon at the top right corner of the screen to see your Profile
        information.
      </p>
      <LazyLoadImage
        src={MyProfileButton}
        alt="profile button"
        height={350}
        imgClassName="h-auto w-full mb-2"
      />
      <LazyLoadImage src={MyProfilePage} alt="profile page" height={350} />
      <p className="mt-0">You can:</p>
      <UnorderedList>
        <li className="ml-5">Update Phone Number</li>
        <LazyLoadImage src={UpdatePhone} alt="hearings" height={350} className="mb-4 mt-2" />
        <li className="ml-5">Update Email</li>
        <LazyLoadImage src={UpdateEmail} alt="hearings" height={350} className="mb-4 mt-2" />
        <li className="ml-5">Change Password</li>
        <LazyLoadImage src={ChangePassword} alt="hearings" height={350} className="mb-4 mt-2" />
      </UnorderedList>
    </Accordion>
  );
};
