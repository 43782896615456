export const toCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const trimExtraSpaces = (str: string) => {
  return str.replace(/\s+/g, ' ').trim();
};
