// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import FederalOffsetImg from '../../assets/FederalOffset.webp';

export const FederalOffset = () => {
  return (
    <Accordion summary="How do I view Federal offset information?">
      <p className="mt-0">
        The Federal Offset tab contains links to different programs that provide helpful
        information.
      </p>
      <LazyLoadImage src={FederalOffsetImg} alt="federal offset" height={350} />
    </Accordion>
  );
};
