// Project
import { useHicData } from 'src/hooks';

// Feature
import { Balance } from '../types';

export const useBalances = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };
  const results = useHicData<Balance[]>('/balance', queryParameters);

  console.log('useBalances', results);
  return results;
};
