// External
import { lazy, ReactNode } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { MUIDataTableColumn, MUIDataTableOptions, TableBody } from 'mui-datatables';

// Local
import { DefaultPaginationFooter } from './DefaultPaginationFooter';
import { TableBodySpinner } from './TableBodySpinner';

// Lazy load to reduce bundle size
const MUIDataTable = lazy(() => import(/* webpackChunkName: "mui-datatables" */ 'mui-datatables'));

// NOTE: Not sure why this is needed. Without code splitting for additional mui-datatables component,
// this will appear in the main bundle when not needed
const ExpandButton = lazy(
  () => import(/* webpackChunkName: "datatables-expand-button" */ 'mui-datatables')
);

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

interface ICustomMuiDataTable {
  data: Array<object>;
  columns: MUIDataTableColumn[];
  options: MUIDataTableOptions;
  customExpandButton?: JSX.Element | ((muiDataTableProps) => JSX.Element);
  isFetchingNextData?: boolean;
}

const DefaultExpandButton = (props) => {
  return <ExpandButton {...props} />;
};

export const CustomMuiDataTable = ({
  data,
  columns,
  options,
  customExpandButton,
  isFetchingNextData,
}: ICustomMuiDataTable) => {
  const mainTheme = useTheme();
  const theme = createTheme(mainTheme, {
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            paddingLeft: '5px',
            paddingRight: '5px',
            minHeight: '15vh',
            flex: '1',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          },
        },
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            display: 'none',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          sortAction: {
            alignItems: 'center',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:last-child > td': {
              borderBottom: 'none',
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: mainTheme.palette.primary.main,
            },
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          ul: {
            justifyContent: 'end',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={''}
        data={data}
        options={options}
        columns={columns}
        components={{
          TableBody: isFetchingNextData ? TableBodySpinner : (TableBody as unknown as ReactNode),
          TableFooter: DefaultPaginationFooter,
          ExpandButton: customExpandButton ? customExpandButton : DefaultExpandButton,
        }}
      />
    </ThemeProvider>
  );
};
