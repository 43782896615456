// External
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FormEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

// Project
import { CenterLayout } from 'src/layout';
import { Spinner } from 'src/components/Spinner';
import { FormCodes } from '../../types/enum';
import { ExternalLink } from 'src/components/Link';
import { CSEA_CUSTOMER_SERVICE_URL } from 'src/config';
import { useUserType } from 'src/hooks';

// Feature
import { useMaxForms, useFormList } from '../../api';
import { ParentFormTitle } from '../../components/RequestMail';

export const RequestMailSubmission = ({ submit }) => {
  const queryClient = useQueryClient();
  const { appUser, userIdentifier, caseId } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const { data: userTypeData } = useUserType(caseId, userIdentifier, isAppUserAgency);
  const userType = userTypeData?.user_type || '';
  const {
    data: maxFormsData,
    isLoading: maxFormsLoading,
    error: maxFormsError,
  } = useMaxForms('form-request-limit');
  const maxForms = maxFormsData?.result || '';

  const {
    data: formListData,
    isLoading: formListLoading,
    error: formListError,
  } = useFormList(caseId, userIdentifier, isAppUserAgency);

  function getFormName(formId: string): string {
    // return form name or form_id if not found
    return FormCodes[formId] || formId;
  }

  const [forms, setForms] = useState<Object | undefined>();

  useEffect(() => {
    if (!formListLoading && formListData) {
      const initialForms = {};
      formListData.forEach((form) => {
        initialForms[form.form_id] = false;
      });
      setForms(initialForms);
    }
  }, [formListData, formListLoading]);

  const handleFormSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setForms({
      ...forms,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const selectedForms = Object.keys(forms).filter((formId) => forms[formId]);
    // Need to clear cache so that on return to form page, most recent list will show
    queryClient.removeQueries({ queryKey: ['hic', '/form/list'] });
    // Need to clear cache so that submitted page shows already submitted forms
    queryClient.removeQueries({ queryKey: ['hic', '/form/requested'] });
    submit(selectedForms);
  };

  if (formListLoading || maxFormsLoading) {
    return (
      <CenterLayout>
        <Spinner />
      </CenterLayout>
    );
  }

  if (formListError || maxFormsError) {
    const formError = formListError?.response?.data?.message;
    const maxError = maxFormsError?.response?.data?.message;

    if (formError === 'User address is not verified') {
      return (
        <div>
          <p>
            <b className="text-2xl">Forms</b>
          </p>
          <p>
            You do not appear to have a valid address for this account. Please contact a customer
            service representative or mail an address update to the nearest{' '}
            <ExternalLink text="CSEA office" href={CSEA_CUSTOMER_SERVICE_URL} /> along with your
            request.
          </p>
        </div>
      );
    }

    return <span>{formError || maxError}</span>;
  }

  if (!formListData.length || formListData[0].error || forms === undefined) {
    return (
      <div>
        <p>
          <b className="text-2xl">Forms</b>
        </p>
        <p className="mb-6">You have already requested all of the forms for this case.</p>
      </div>
    );
  }

  const shouldDisableButton = !Object.values(forms).some((form) => form);
  return (
    <div>
      <p>
        <b className="text-2xl">Forms</b>
      </p>
      <p className="mb-6">
        When requesting forms, you may select each form for a maximum of {maxForms} per case, per
        day. Please choose which forms you need. These forms will be mailed to the address on your
        account.
      </p>
      <ParentFormTitle userType={userType} />
      <form className="flex flex-col" onSubmit={handleSubmit}>
        {formListData.map((form) => (
          <div key={form.form_id}>
            <FormControlLabel
              label={getFormName(form.form_id)}
              control={
                <Checkbox
                  checked={forms[form.form_id]}
                  name={form.form_id}
                  onChange={handleFormSelection}
                />
              }
            />
          </div>
        ))}
        <Button
          variant="contained"
          size="small"
          className="mt-8 h-10 w-1/6"
          type="submit"
          disabled={shouldDisableButton}
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
