// External
import { useState } from 'react';

// Project
import { CustomMuiDataTable } from 'src/components/Table';
import { IHookError } from 'src/types';

// Features
import { getTableColumns } from './tableColumns';
import { getTableOptions } from './tableOptions';
import { ConfirmModal } from '../ConfirmModal';
import { ILockedAccounts } from '../../types';

interface TableProps {
  data: ILockedAccounts;
  error: IHookError;
  update: (email: string) => Promise<void>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const LockedAccountsTable = ({ data, error, update, page, setPage }: TableProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  if (error) return <div>Error: {error.response?.data.message}</div>;

  const results = data.blocked_users;
  const totalPages = data.total_pages;

  return (
    <>
      <CustomMuiDataTable
        data={results}
        options={getTableOptions(totalPages, page, setPage)}
        columns={getTableColumns(setModalOpen, setEmail)}
      />
      <ConfirmModal
        loading={loading}
        showDialog={modalOpen}
        email={email}
        handleClose={() => setModalOpen(false)}
        handleOk={async () => {
          setLoading(true);
          await update(email);
          setLoading(false);

          setModalOpen(false);
          setPage(0);
        }}
      />
    </>
  );
};
