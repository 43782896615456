// External
import { Fragment } from 'react';

// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import { OrderList } from '../shared/List';
import CustomerSignIn from '../../assets/CustomerSignIn.webp';
import PinCheck from '../../assets/PinCheck.webp';
import Acknowledgement from '../../assets/Acknowledgement.webp';
import CreateAccountImg from '../../assets/CreateAccount.webp';
import VerifyPhone from '../../assets/VerifyPhone.webp';
import ConfirmMfa from '../../assets/ConfirmMfa.webp';
import EmailVerify from '../../assets/EmailVerify.webp';
import LinkAccount from '../../assets/LinkAccount.webp';

const steps = [
  {
    text: 'New customers can create an account by clicking on the “Create An Account” link on the Login Screen.',
    img: CustomerSignIn,
    alt: 'customer sign in page',
    height: 600,
  },
  {
    text: 'Customer needs PIN & Access Code in order to create a new account',
    img: PinCheck,
    alt: 'hello',
    height: 400,
  },
  {
    text: 'You are required to agree with the Terms and Conditions before going further.',
    img: Acknowledgement,
    alt: 'hello',
    height: 550,
  },
  {
    text: 'All fields on this screen are required.',
    img: CreateAccountImg,
    alt: 'hello',
    height: 550,
  },
  {
    text: 'After you verify your phone number, you will be redirected to the login screen again to login using your email/password.',
    img: VerifyPhone,
    alt: 'hello',
    height: 400,
  },
  {
    text: 'Input the 6-digit Multi-Factor Authentication (MFA) code that was sent to your phone number.',
    img: ConfirmMfa,
    alt: 'hello',
    height: 400,
  },
  {
    text: 'Input the 6-digit code sent to your email to verify your email address.',
    img: EmailVerify,
    alt: 'hello',
    height: 400,
  },
  {
    text: 'Enter your CSEA PIN and Access Code to link to your CSEA account.',
    img: LinkAccount,
    alt: 'hello',
    height: 400,
  },
];

export const CreateAccount = () => {
  return (
    <Accordion summary="How do I create an account?">
      <OrderList>
        {steps.map((step, index) => (
          <Fragment key={index}>
            <li className="ml-5">{step.text}</li>
            <LazyLoadImage src={step.img} alt={step.alt} height={step.height} className="my-4" />
          </Fragment>
        ))}
      </OrderList>
    </Accordion>
  );
};
