// External
import { Page, View, Document, usePDF, Text } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { PageNumber } from 'src/components/Pdf';

// Feature
import { useCpStatement } from 'src/features/payments/api';
import {
  CaseInfo,
  PaymentInfoTable,
  CurrentSupportTable,
  ReimbursementTable,
  OtherDisburseTable,
} from '../components/CpStatement/PrintCpStatement';

const CpStatementDocument = ({ data }) => {
  const {
    border_b,
    border_style_dashed,
    mt_3,
    mb_3,
    font_bold,
    flex,
    flex_row,
    text_lg,
    text_xl,
    underline,
  } = pdfStyles;
  return (
    <Document>
      <Page size="LETTER" style={pdfStyles.pageDefault}>
        <CaseInfo info={data} />
        <View style={[border_b, border_style_dashed, mt_3, mb_3]} />
        <View style={[flex, flex_row, mt_3, mb_3]}>
          <Text>
            <Text style={[font_bold, text_lg, underline]}>FOR INFORMATIONAL PURPOSES ONLY</Text>
            <Text style={[text_lg]}>
              . Any and all information on this statement is provided for informational purposes
              only and is not to be relied upon as an official record.
            </Text>
          </Text>
        </View>
        <Text style={[font_bold, text_xl]}>Custodial Parent Quarterly Statement</Text>
        <Text style={[mt_3, mb_3]}>
          Custodial Parent Statement of Account for: {data[0].period_info[0].start_period} through{' '}
          {data[0].period_info[0].end_period}
        </Text>
        <PaymentInfoTable payments={data} />
        <View style={[mt_3, mb_3]}>
          <CurrentSupportTable support={data} />
        </View>
        <ReimbursementTable payments={data} />
        <View style={[mt_3, mb_3]} />
        <OtherDisburseTable payments={data} />
        <PageNumber />
      </Page>
    </Document>
  );
};

export const useCpPrintStatement = (caseId: string, userType: string) => {
  const location = useLocation();
  const path = location.pathname;
  let isOnCpStatementPage = path.split('/').pop() === 'cp-statement';

  const { data, isLoading, isError } = useCpStatement(caseId);

  const shouldRenderPdf = isLoading || isError;

  const [instance, update] = usePDF({
    document: shouldRenderPdf ? null : <CpStatementDocument data={data} />,
  });

  useEffect(() => {
    update(shouldRenderPdf ? null : <CpStatementDocument data={data} />);
  }, [data, isLoading, shouldRenderPdf, update]);

  let result = {
    displayCpStatementPrintButton: isOnCpStatementPage,
    disableCpStatementPrintButton: shouldRenderPdf,
    data: {
      href: instance.url,
      download: `cp-statement.pdf`,
    },
  };

  console.log('useCpPrintStatement', result);
  return result;
};
