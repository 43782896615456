import { StyleSheet, Font } from '@react-pdf/renderer';

import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf';
import OpenSansBold from '../assets/fonts/OpenSans-Bold.ttf';

Font.register({ family: 'OpenSans', fonts: [{ src: OpenSansRegular }] });
Font.register({ family: 'OpenSans-Bold', fonts: [{ src: OpenSansBold }] });

// Generally follows the tailwindcss framework
// Spacing: https://tailwindcss.com/docs/customizing-spacing
// Some exceptions such as borderTop
// System is based on n * 4
// Order of styles are:
// - all
// - top
// - bottom
// - left
// - right

// IMPORTANT: ORDER OF STYLES IN STYLE ARRAY MATTERS
// i.e. [p_2, pl_3] vs [pl_3, p_2] will yield different results

export default StyleSheet.create({
  // Position
  absolute: {
    position: 'absolute',
  },
  // Top
  top_0: {
    top: 0,
  },
  // Bottom
  bottom_7: {
    bottom: 28,
  },
  // Left
  left_0: {
    left: 0,
  },
  // Right
  right_0: {
    right: 0,
  },
  // Flexbox
  flex: {
    display: 'flex',
  },
  flex_1: {
    flex: 1,
  },
  flex_row: {
    flexDirection: 'row',
  },
  justify_center: {
    justifyContent: 'center',
  },
  justify_between: {
    justifyContent: 'space-between',
  },
  justify_start: {
    justifyContent: 'flex-start',
  },
  //// Width
  w_50: {
    width: '50%',
  },
  w_33: {
    width: '33%',
  },
  // Typography
  //// Format
  underline: {
    textDecoration: 'underline',
  },
  //// Font Size
  text_lg: {
    fontSize: 10,
  },
  text_m: {
    fontSize: 12,
  },
  text_xl: {
    fontSize: 14,
  },
  //// Font Weight
  font_bold: {
    fontFamily: 'OpenSans-Bold',
  },
  //// Line Height
  leading_2: {
    lineHeight: 2,
  },
  //// Text Align
  text_right: {
    textAlign: 'right',
  },
  text_left: {
    textAlign: 'left',
  },
  text_center: {
    textAlign: 'center',
  },
  // Spacing
  //// Margin
  m_2: {
    margin: 8,
  },
  mt_2: {
    marginTop: 8,
  },
  mt_3: {
    marginTop: 12,
  },
  mb_1: {
    marginBottom: 4,
  },
  mb_2: {
    marginBottom: 8,
  },
  mb_3: {
    marginBottom: 12,
  },
  mb_4: {
    marginBottom: 16,
  },
  mb_5: {
    marginBottom: 20,
  },
  mb_6: {
    marginBottom: 24,
  },
  ml_0: {
    marginLeft: 0,
  },
  ml_3: {
    marginLeft: 12,
  },
  //// Padding
  p_1: {
    padding: 4,
  },
  p_2: {
    padding: 8,
  },
  p_3: {
    padding: 12,
  },
  pb_1: {
    paddingBottom: 4,
  },
  pb_2: {
    paddingBottom: 8,
  },
  pb_3: {
    paddingBottom: 12,
  },
  pb_4: {
    paddingBottom: 16,
  },
  pb_5: {
    paddingBottom: 24,
  },
  pl_0: {
    paddingLeft: 0,
  },
  pl_1: {
    paddingLeft: 4,
  },
  pl_2: {
    paddingLeft: 8,
  },
  pl_3: {
    paddingLeft: 12,
  },
  pl_8: {
    paddingLeft: 32,
  },
  pr_11: {
    paddingRight: 44,
  },
  // Borders
  //// Border Color
  border_slate_600: {
    borderColor: '#475569',
  },
  border_black: {
    borderColor: '#000',
  },
  border_grayscale: {
    borderColor: '#BDBDBD',
  },
  //// Border Width
  border_t_0: {
    borderTop: 0,
  },
  border_t: {
    borderTop: 0.5,
  },
  border_t_2: {
    borderTop: 1,
  },
  border_b: {
    borderBottom: 1,
  },
  border_b_2: {
    borderBottom: 2,
  },
  border_l: {
    borderLeft: 1,
  },
  border_r: {
    borderRight: 1,
  },
  //// Border Style
  border_style_dashed: {
    borderStyle: 'dashed',
  },
  // Page default
  pageDefault: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'OpenSans',
  },
});
