// External
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';

// Project
import { CSEA_CUSTOMER_SERVICE_URL } from 'src/config';
import * as Auth from 'src/lib/auth';

// Feature
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';
import { ExternalLink } from 'src/components/Link';
import { signOut } from 'src/lib/auth';

// Types
import { LinkAccountType } from '../types/uam';

const LinkKeiki = (): React.ReactElement => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showLockout, setShowLockout] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LinkAccountType>({
    pin: '',
    accessCode: '',
    email: '',
  });
  const [error, setError] = useState('');

  const isAccountLinked = async () => {
    try {
      let token = await Auth.getToken();
      let data = await API.get('case', '/user', {
        headers: {
          Authorization: token,
        },
      });
      const message = data?.message;
      if (message.first_name && message.last_name) {
        navigate(PATHS.customerCases);
      } else {
        throw Error('No user found.');
      }
    } catch (err) {
      console.log('isAccountLinkedError', err.response);
      if (err.response?.data.message === 'User not found') {
        const isLockedOut = err.response.data.is_locked_out;
        setShowLockout(isLockedOut);
        setShowForm(!isLockedOut);
      } else {
        setShowServerError(true);
      }
    }
  };

  useEffect(() => {
    isAccountLinked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLink = async () => {
    const { pin, accessCode } = data;
    try {
      setLoading(true);
      const email = await Auth.getEmail();
      await API.post('case', '/link-account', {
        headers: {
          Authorization: await Auth.getToken(),
        },
        body: {
          email,
          pin,
          code: accessCode,
        },
      });
      navigate(PATHS.customerCases);
    } catch (err) {
      setLoading(false);
      const message = err.response.data?.message;
      if (message === 'User account is locked. Please contact the administrator.') {
        setError('');
        setShowLockout(true);
      } else {
        console.log('linkError', err.response);
        let error;
        switch (message) {
          case 'Account already exists with the same pin':
            error = 'An account already exists with the same PIN.';
            break;
          case 'Account is not linked. Possibly a wrong pin and access code combination':
          case 'Invalid request received. Pin or code validation failed. Unable to update the user profile':
            error = 'Invalid PIN and/or Access Code.';
            break;
          default:
            error = 'Invalid PIN and/or Access Code.';
        }
        error +=
          ' After 3 unsuccessful linking attempts, you will be locked out for a period of 15 minutes.';

        setError(error);
      }
    }
  };

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleOnCancel = () => {
    signOut();
    navigate(PATHS.signIn('customer'));
  };

  if (showLockout) {
    return (
      <>
        <h1 className="text-center text-2xl">Link to CSEA Account</h1>
        <p className="link-account-error">
          You have reached the maximum attempts to Link to your CSEA Account and will be locked out
          for a period of 15 minutes. For questions or issues regarding PIN and Access Code, please
          contact CSEA <ExternalLink text="Customer Service" href={CSEA_CUSTOMER_SERVICE_URL} />.
        </p>
        <Button variant="contained" className="mt-2 w-full" onClick={handleOnCancel}>
          Back to Sign In
        </Button>
      </>
    );
  }

  if (showForm) {
    return (
      <>
        <h1 className="text-center text-2xl">Link to CSEA Account</h1>
        <p>
          To link this account to your CSEA account, input your CSEA PIN and Access Code below. For
          questions or issues regarding PIN and Access Code, please contact CSEA{' '}
          <ExternalLink text="Customer Service" href={CSEA_CUSTOMER_SERVICE_URL} />.
        </p>
        <TextInput
          autoComplete="off"
          name="pin"
          label="PIN"
          value={data.pin}
          placeholder="Enter your PIN"
          required
          onChange={handleOnChange}
          error={error !== ''}
          className="mb-4"
        />
        <TextInput
          autoComplete="off"
          name="accessCode"
          label="Access Code"
          value={data.accessCode}
          placeholder="Enter code here"
          required
          onChange={handleOnChange}
          error={error !== ''}
        />
        {error !== '' && <div className="link-account-error mt-1 text-red-600">{error}</div>}
        <div className="mb-6 flex justify-between gap-5 pt-5">
          <Button variant="outlined" onClick={handleOnCancel} className="w-1/2">
            Cancel
          </Button>
          <LoadingButton
            className="w-1/2"
            loading={loading}
            onClick={handleLink}
            variant="contained"
          >
            <span>Link</span>
          </LoadingButton>
        </div>
      </>
    );
  }

  if (showServerError) {
    return (
      <Box className="flex flex-col items-center justify-center gap-7">
        <div className="text-center">
          <h2>Verifying CSEA Account Connection</h2>
          <p>Sorry, looks like something's wrong. Please try again in a few minutes.</p>
        </div>
      </Box>
    );
  }

  return (
    <>
      <Box className="flex flex-col items-center justify-center gap-7">
        <div className="text-center">
          <h2>Verifying CSEA Account Connection</h2>
          <p>Please wait while we check if your account has been linked to CSEA account.</p>
        </div>
        <CircularProgress />
      </Box>
    </>
  );
};

export default LinkKeiki;
