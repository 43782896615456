// External
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Project
import {
  setLocalAppUser,
  getLocalAppUser,
  doesALocalAppUserExist,
  clearLocalAppUser,
} from 'src/utils/localStorage';
import PATHS from 'src/config/paths';

export const useLocalAppUser = () => {
  const location = useLocation();
  const signInPaths = [PATHS.signIn('customer'), PATHS.signIn('agency')];
  const onSignInPage = signInPaths.find((path) => path === location.pathname);
  const [localAppUser, setAppUser] = useState<string>();

  useEffect(() => {
    const updateOnPageReload = async () => {
      const user = await getLocalAppUser();
      setAppUser(user);
    };

    if (onSignInPage) {
      clearLocalAppUser();
    }

    if (doesALocalAppUserExist()) {
      // Condition for when user refreshes the page
      updateOnPageReload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { localAppUser, setLocalAppUser };
};
