export const splitNameToFirstAndLastName = (name: string) => {
  const commaIndex = name.lastIndexOf(',');
  if (commaIndex === -1) {
    return { firstName: '', lastName: name.trim() };
  }

  const lastName = name.slice(0, commaIndex).trim();
  const firstName = name.slice(commaIndex + 1).trim();

  return { firstName, lastName };
};
