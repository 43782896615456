// Project
import { useHicData } from 'src/hooks';
import { RequestedForm } from '../types';

export const useRequestedForms = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const response = useHicData<Array<RequestedForm>>('/form/requested', queryParameters);
  console.log('useRequestedForms', response);
  return response;
};
