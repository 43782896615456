// Project
import { useHicData } from 'src/hooks';
import { UserType } from 'src/types';

export const useUserType = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const results = useHicData<UserType>('/user', queryParameters);
  console.log('useUserType', results);
  return results;
};
