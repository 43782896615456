// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';

// Feature
import { AmountCell } from '../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  balanceToWidth: 350,
  amountWidth: 100,
};

const {
  font_bold,
  text_xl,
  pb_4,
  flex_row,
  p_2,
  border_t,
  border_t_0,
  pl_3,
  border_slate_600,
  border_black,
} = pdfStyles;

const TableBody = ({ cpBalance, stateBalance }) => {
  return (
    <>
      <View style={[flex_row, p_2, pl_3, border_t_0]}>
        <Text style={{ minWidth: tableProp.balanceToWidth }}>Balance Owed to CP</Text>
        <AmountCell amount={cpBalance} width={tableProp.amountWidth} />
      </View>
      <View style={[flex_row, p_2, pl_3, border_t, border_slate_600]}>
        <Text style={{ minWidth: tableProp.balanceToWidth }}>Balance Owed to State</Text>
        <AmountCell amount={stateBalance} width={tableProp.amountWidth} />
      </View>
    </>
  );
};

const TableFooter = ({ caseBalance }) => {
  return (
    <View style={[border_t, border_black, p_2, pl_3, flex_row]}>
      <Text style={{ minWidth: tableProp.balanceToWidth }}>Total Case Balance</Text>
      <AmountCell amount={caseBalance} width={tableProp.amountWidth} />
    </View>
  );
};

export const BalancesTable = ({ balances }) => {
  const { case_balance, cp_balance, state_balance } = balances[0];
  if (cp_balance === 0 && state_balance === 0) {
    return (
      <>
        <Text style={[font_bold, text_xl, pb_4]}>Balances</Text>
        <Text>This case has a zero balance.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_xl, pb_4]}>Balances</Text>
      <TableBody cpBalance={cp_balance} stateBalance={state_balance} />
      <TableFooter caseBalance={case_balance} />
    </>
  );
};
