export const CognitoPasswordRequirement = () => (
  <div className="text-red-600">
    <span>Password Requirements</span>
    <ul>
      <li>Minimum 8 characters</li>
      <li>
        Mix of each character type: uppercase, lowercase, number, and special character (! @ # $ % ^
        & * ( ) _ + - = [ ] {} | ')
      </li>
      <li>Not identical to your email address</li>
      <li>No spaces allowed</li>
    </ul>
  </div>
);
