// Project
import { CP_TYPE_LIST, NCP_TYPE_LIST } from 'src/config/user-type-list';

export const ParentFormTitle = ({ userType }) => {
  return (
    <>
      {CP_TYPE_LIST.includes(userType) && (
        <h2 className="text-base text-primary">Custodial Parent Forms</h2>
      )}
      {NCP_TYPE_LIST.includes(userType) && (
        <h2 className="text-base text-primary"> Non-Custodial Parent Forms</h2>
      )}
    </>
  );
};
