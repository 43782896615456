// External
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

// Initial format: https://day.js.org/docs/en/parse/string-format
// Final format: https://day.js.org/docs/en/display/format
export const formatDate = (
  date: number | string,
  initialFormat: string,
  finalFormat: string
): string => {
  return dayjs(String(date), initialFormat).format(finalFormat);
};
