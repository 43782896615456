// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';

// Feature
import { AmountCell } from '../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  amountWidth: 140,
  amountRightPadding: 80,
  paymentDateWidth: 205,
  periodWidth: 150,
};

const { font_bold, text_m, pb_4, flex_row, pl_2, pb_3, p_2, border_t, border_slate_600 } =
  pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_2, pb_3]}>
    <Text style={[{ minWidth: tableProp.amountWidth }, font_bold]}>Payment Applied</Text>
    <Text style={[{ minWidth: tableProp.paymentDateWidth }, font_bold]}>Payment Date</Text>
    <Text style={[{ minWidth: tableProp.periodWidth }, font_bold]}>Month Applied</Text>
  </View>
);

const TableBody = ({ payments }) => {
  return payments
    .slice(0)
    .reverse()
    .map((payment, index) => (
      <View key={index} style={[flex_row, p_2, pl_2, border_t, border_slate_600]}>
        <AmountCell
          width={tableProp.amountWidth}
          paddingRight={tableProp.amountRightPadding}
          amount={payment.alloc_amt}
        />
        <Text style={{ minWidth: tableProp.paymentDateWidth }}>
          {formatDate(payment.date_recd, 'YYYYMMDD', 'MMMM D, YYYY')}
        </Text>
        <Text style={{ minWidth: tableProp.periodWidth }}>
          {formatDate(payment.alloc_period, 'YYYYMM', 'MMMM YYYY')}
        </Text>
      </View>
    ));
};

export const PaymentsAppliedTable = ({ payments }) => {
  if (payments === null) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>Payments Applied</Text>
        <Text>No amounts have been applied within the last 12 months.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>Payments Applied</Text>
      <TableHeader />
      <TableBody payments={payments} />
    </>
  );
};
