// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';

// Feature
import { AmountCell } from '../PDF/AmountCell';

const tableProp = {
  // Header widths
  amountWidth: 150,
  paymentReceivedRightPadding: 50,
  receivedWidth: 180,
  appliedWidth: 180,
  appliedLeftPadding: 20,
  appliedRightPadding: 30,
  periodWidth: 120,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t, text_right } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ width: tableProp.amountWidth }, font_bold]}>Payment Received</Text>
    <Text style={[{ width: tableProp.receivedWidth }, font_bold]}>Received Date</Text>
    <Text style={[{ width: tableProp.appliedWidth }, font_bold]}>Amount Applied to Case</Text>
    <Text style={[{ width: tableProp.periodWidth }, text_right, font_bold]}>Applied Period</Text>
  </View>
);

const TableBody = ({ payments }) => {
  let previousRectNbr = null;
  let maxAllocAmt = 0; // Track the maximum alloc_amt

  payments.forEach((payment) => {
    if (payment.alloc_amt > maxAllocAmt) {
      maxAllocAmt = payment.alloc_amt;
    }
  });

  return payments.map((payment, index) => {
    const currentRectNbr = payment.rect_nbr;
    const isFirstRow = currentRectNbr !== previousRectNbr;

    previousRectNbr = currentRectNbr;

    return (
      <View key={index} style={[flex_row, p_2, pl_3, border_t]}>
        {isFirstRow && (
          <>
            <AmountCell
              width={tableProp.amountWidth}
              paddingRight={tableProp.paymentReceivedRightPadding}
              amount={payment.pmt_amt}
            />
            <Text style={{ width: tableProp.receivedWidth }}>
              {formatDate(payment.date_recd, 'YYYYMMDD', 'MMMM D, YYYY')}
            </Text>
          </>
        )}
        {!isFirstRow && (
          <>
            <View style={{ width: tableProp.amountWidth }} />
            <Text style={{ width: tableProp.receivedWidth }} />
          </>
        )}
        <View style={{ width: tableProp.appliedWidth, alignItems: 'flex-end' }}>
          <AmountCell
            width={tableProp.appliedWidth}
            paddingLeft={tableProp.appliedLeftPadding}
            paddingRight={tableProp.appliedRightPadding}
            amount={payment.alloc_amt}
          />
        </View>
        <Text
          style={[
            {
              width: tableProp.periodWidth,
            },
            text_right,
          ]}
        >
          {formatDate(payment.alloc_period, 'YYYYMM', 'MMMM YYYY')}
        </Text>
      </View>
    );
  });
};

export const PaymentsReceivedTable = ({ payments }) => {
  if (payments === undefined || payments.length === 0) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>Payments Received</Text>
        <Text>No amounts have been applied within the last 12 months.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>Payments Received</Text>
      <TableHeader />
      <TableBody payments={payments} />
    </>
  );
};
