// Project
import { useHicData } from 'src/hooks';

// Feature
import { IBillStatement } from '../types';

export const useBillStatement = (caseId: string) => {
  const queryParameters = {
    case_number: caseId,
  };

  const results = useHicData<IBillStatement[]>('/apbill/', queryParameters);
  console.log('useBillStatement', results);
  return results;
};
