// Project
import { useHicData } from 'src/hooks';

export const useStatus = (caseId: string, pin: string, isAppUserAgency: boolean) => {
  const queryParameters = {
    case_number: caseId,
    ...(isAppUserAgency && { pin }),
  };

  const response = useHicData<Array<string>>('/case-status', queryParameters);
  console.log('useStatus', response);
  return response;
};
