/**
 * Because this is a SPA, when a user refreshes their page
 * on the deployed site a FileNotFound will be thrown.
 * This function allows React Router to redirect to proper page
 * internally.
 *
 * If you refresh the deployed site, you'll see #2142!/ gets
 * temporarily inserted into the URL.
 */
export const redirectOnDeployedRefresh = () => {
  // eslint-disable-next-line no-restricted-globals
  let path = location.hash?.split('#2142!')[1];
  if (path) {
    // eslint-disable-next-line no-restricted-globals
    location.hash = '';
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '', `${process.env.PUBLIC_URL}${path}`);
  }
};

export const navigationUrlParser = (location: string): { [name: string]: string } => {
  let prop = {};
  let identifiers = [
    'appUser',
    'userIdentifier',
    'base',
    'caseId',
    'caseMainTab',
    'caseSubtab',
    'state',
  ];
  let url = location.split('/');

  // ignore empty element bc of leading slash
  url.shift();

  url.forEach((value, index) => {
    let identifierName = identifiers[index];
    if (value !== null) prop[identifierName] = url[index];
  });
  return prop;
};
