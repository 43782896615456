// External
import { useParams } from 'react-router-dom';

// Project
import { Spinner } from 'src/components/Spinner';
import { useSettingByOption, useUserType } from 'src/hooks';
import { CenterLayout } from 'src/layout';

// Feature
import { useEnforcement } from './api';
import { EnforcementsTable, Footer, Header } from './components';

export const Enforcements = () => {
  const { appUser, userIdentifier, caseId } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const {
    data: flag,
    isLoading: isFlagLoading,
    error: isFlagError,
  } = useSettingByOption('enforcement-license-status');
  const {
    data: enforcement,
    isLoading,
    error,
  } = useEnforcement(caseId, userIdentifier, isAppUserAgency);
  const { data: userTypeData } = useUserType(caseId, userIdentifier, isAppUserAgency);
  const userType = userTypeData?.user_type;

  if (isLoading || isFlagLoading)
    return (
      <CenterLayout>
        <Spinner />
      </CenterLayout>
    );

  if (error) return <span>Error: {error.response?.data.message}</span>;
  if (isFlagError) return <span>Error: {isFlagError.response?.data.message}</span>;

  const data = enforcement[0];
  const isFlagOn = flag.result === 'enable' ? true : false;
  console.log('isFlagOn :>> ', isFlagOn);

  if (
    data.cr_date > 0 ||
    data.st_date > 0 ||
    data.fed_date > 0 ||
    data.oiw_amt > 0 ||
    (data.oiw_order === 'Y' && data.oiw_served === 'Y') ||
    data.release_date > 0 ||
    data.notice_referral_date > 0 ||
    data.notice_cert_date > 0
  ) {
    return (
      <div>
        <Header />
        <p>
          The following enforcement actions have been taken{' '}
          {userType === 'NCP4D' ? 'on your case:' : 'against the Non-Custodial Parent.'}
        </p>
        <EnforcementsTable data={data} userType={userType} isFlagOn={isFlagOn} />
        <Footer data={data} isFlagOn={isFlagOn} />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <p>There is no enforcement action taken on your case.</p>
      <Footer data={data} isFlagOn={isFlagOn} />
    </div>
  );
};
