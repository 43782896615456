// External
import { QueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

// Project
import * as Auth from 'src/lib/auth';

// Need this function because of the way data is structured
export const fetchData = async (queryParameters: any): Promise<any> => {
  try {
    let token = await Auth.getToken();
    let res = await API.get('hic', '/search', {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryParameters,
    });
    return res;
  } catch (err) {
    console.log('hic error', err.response);
    throw err;
  }
};

export const prefetchNextPage = (
  queryClient: QueryClient,
  queryKey: (string | number)[],
  page: number,
  totalPage: number,
  nextPayload: any
): void => {
  if (page + 1 <= totalPage) {
    queryClient.prefetchQuery({
      queryKey,
      queryFn: async () => fetchData(nextPayload),
    });
  }
};
