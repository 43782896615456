// Project
import { useHicData } from 'src/hooks';

// Feature
import { ICpStatement } from '../types';

export const useCpStatement = (caseId: string) => {
  const queryParameters = {
    case_number: caseId,
    user_type: 'CP',
  };

  const results = useHicData<ICpStatement[]>('/statement/', queryParameters);
  console.log('useCpStatement', results);
  return results;
};
