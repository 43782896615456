// Project
import { useCaseData } from 'src/hooks';
import { User } from 'src/types';

export const useUserInfo = (pin: string, isAppUserAgency: boolean) => {
  const queryParameters = isAppUserAgency && !isNaN(+pin) && { pin };
  const results = useCaseData<User>('/user', queryParameters);

  console.log('useUserInfo', results);
  return results;
};
