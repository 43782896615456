// External
import { Container } from '@mui/material';

const Header = () => {
  return (
    <Container
      className="pl-14 py-6 mb-0"
      sx={{ background: "#fff url('/header-background.jpg') 0% 20% no-repeat" }}
    >
      <div className="flex gap-14">
        <img className="h-24" alt="State of Hawaii Seal" src="/seal_of_hawaii.png" />
        <div className="pt-2">
          <span className="text-sm">State of Hawaii</span>
          <h1 className="text-base font-bold text-primary m-0">Child Support Enforcement Agency</h1>
        </div>
      </div>
    </Container>
  );
};

export default Header;
