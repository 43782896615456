// Project
import { useState } from 'react';
import { Button, RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// Project
import PATHS from 'src/config/paths';

const PinCheck = () => {
  const navigate = useNavigate();
  const [radioValue, setRadioValue] = useState('Yes');

  const updateButtonsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (radioValue === 'Yes') {
      navigate(PATHS.termsConditions);
    } else {
      navigate(PATHS.noPinAccess);
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl">New Account</h1>
      Your CSEA Personal Identification Number (PIN) and Access Code are required to create an
      account. Do you have your PIN and Access Code?
      <form onSubmit={handleOnSubmit} id="pin-check-form">
        <FormControl>
          <RadioGroup name="yesNoRadioGroup" onChange={updateButtonsValue}>
            <FormControlLabel labelPlacement="end" value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel labelPlacement="end" value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </form>
      <Button
        variant="contained"
        type="submit"
        form="pin-check-form"
        className="mt-6 w-full normal-case"
      >
        Next
      </Button>
      <Link
        to={PATHS.signIn('customer')}
        className="mt-5 flex justify-center text-primary no-underline"
      >
        Back to Sign In
      </Link>
    </>
  );
};

export default PinCheck;
