// External
import { useState, useEffect, useRef } from 'react';
import { Button, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from '@tanstack/react-query';

// Project
import { Spinner } from 'src/components/Spinner';
import { CenterLayout } from 'src/layout';

// Feature
import { LockedAccountsTable } from '../components/LockedAccountsTable';
import { unlockLinking, useLinkingLockedAccounts } from '../api';

export const LinkingUnlock = () => {
  const DEBOUNCE_DELAY = 1000;

  const [reloadVar, setReloadVar] = useState(false);
  const [debouncedFilter, setDebouncedFilter] = useState('');
  const [page, setPage] = useState(0);
  const { data, error, isLoading } = useLinkingLockedAccounts(debouncedFilter, page);
  const debounceRef = useRef(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      clearTimeout(debounceRef.current);
    };
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      setPage(0);
      setDebouncedFilter(event.target.value);
    }, DEBOUNCE_DELAY);
  };

  // Force reload the table after unlocking
  // Might also be handy if manual reload button is implemented
  const forceReload = () => {
    queryClient.removeQueries({ queryKey: ['case', '/blocked-users'] });
    setReloadVar(!reloadVar);
  };

  return (
    <>
      <p className="text-lg font-semibold">For Linking Accounts</p>
      <p>List of account being locked from linking to CSEA account</p>
      <label htmlFor="search-by-input" className="mb-1 block font-semibold">
        Filter by Email
      </label>
      <TextField
        className="w-1/2"
        id="outlined-basic"
        label="Enter email address"
        variant="outlined"
        size="small"
        onChange={handleFilterChange}
      />
      <Button
        className="my-0.5 ml-3"
        variant="contained"
        onClick={forceReload}
        startIcon={<RefreshIcon />}
      >
        Refresh
      </Button>
      {isLoading ? (
        <CenterLayout>
          <Spinner />
        </CenterLayout>
      ) : (
        <LockedAccountsTable
          data={data}
          error={error}
          update={async (email: string) => {
            await unlockLinking(email);
            forceReload();
          }}
          page={page}
          setPage={setPage}
        />
      )}
    </>
  );
};
