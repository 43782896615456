// External
import { useState } from 'react';
import { Link, useNavigate, useLocation, Location, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';

// Project
import PATHS from 'src/config/paths';
import { PasswordInput, TextInput } from 'src/components/Form';
import { isCognitoPasswordValid } from 'src/utils/password';
import { PASSWORD_REQUIREMENTS } from 'src/config/messages';

// Feature
import { SuccessModal } from '../modals';

type LocationState = Location & {
  state: {
    email: string;
  };
};

const FORM_DEFAULT = {
  code: '',
  password: '',
  confirmPassword: '',
};

const ERROR_DEFAULT = {
  codeError: false,
  passwordError: false,
  confirmPasswordError: false,
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation() as LocationState;
  const { appUser } = useParams();
  const [form, setForm] = useState(FORM_DEFAULT);
  const [formErrors, setFormErrors] = useState(ERROR_DEFAULT);
  const [errorMsg, setErrorMsg] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showResendConfirmation, setShowResendConfirmation] = useState(false);
  const [showResendError, setShowResendError] = useState(false);

  const resetFormErrors = () => {
    setFormErrors(ERROR_DEFAULT);
    setErrorMsg('');
  };

  const updateFormErrorState = (error: string) => {
    setFormErrors((prev) => ({ ...prev, [error]: true }));
  };

  const handleResendCode = async () => {
    setShowResendConfirmation(false);
    setShowResendError(false);
    try {
      await Auth.forgotPassword(location.state.email);
      setShowResendConfirmation(true);
    } catch (err) {
      console.log('resendCodeError', err);
      setShowResendError(true);
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    resetFormErrors();

    const { code, password, confirmPassword } = form;
    let error = false;

    if (!isCognitoPasswordValid(password)) {
      error = true;
      setErrorMsg('Password does not meet requirements. Please try again.');
      updateFormErrorState('passwordError');
    }

    if (password !== confirmPassword) {
      error = true;
      updateFormErrorState('passwordError');
      updateFormErrorState('confirmPasswordError');
      setErrorMsg('Passwords do not match. Please try again.');
    }

    if (error) return;

    try {
      await Auth.forgotPasswordSubmit(location.state.email, code, password);
      setShowSuccessModal(true);
    } catch (err) {
      console.log('resetPasswordError', err);
      switch (err.name) {
        case 'ExpiredCodeException':
          setErrorMsg('Reset code has expired.');
          break;
        case 'CodeMismatchException':
          setErrorMsg('Wrong reset code. Please try again.');
          updateFormErrorState('codeError');
          break;
        case 'LimitExceededException':
          setErrorMsg('Too many requests. Please try again later.');
          break;
        default:
          setErrorMsg('System error. Please try again later.');
          break;
      }
    }
  };

  const handleOnChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <h1 className="text-center">Reset Your Password</h1>
      <p className="mb-3">A reset code has been sent to your email. Please enter the code below.</p>
      <p className="text-red-600">Note: Resetting your password will not unlock your account.</p>
      <form onSubmit={handleOnSubmit}>
        <TextInput
          autoComplete="off"
          name="code"
          label="Reset Code"
          value={form.code}
          placeholder="Enter code here"
          required
          onChange={handleOnChange}
          error={formErrors.codeError}
          className="mb-3"
        />
        <PasswordInput
          autoComplete="off"
          name="password"
          label="New Password"
          value={form.password}
          placeholder="Password"
          required
          onChange={handleOnChange}
          error={formErrors.passwordError}
          className="mb-3"
        />
        <PasswordInput
          autoComplete="off"
          name="confirmPassword"
          label="Confirm New Password"
          value={form.confirmPassword}
          placeholder="Password"
          required
          onChange={handleOnChange}
          error={formErrors.confirmPasswordError}
        />
        {errorMsg && <div className="text-red-600">{errorMsg}</div>}
        <p className="mb-4">{PASSWORD_REQUIREMENTS}</p>
        {showResendConfirmation && (
          <div className="text-green-600">Reset code has been resent!</div>
        )}
        {showResendError && (
          <div className="text-red-600">System error. Please try again later.</div>
        )}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Submit
        </Button>
      </form>
      <div className="mt-3 flex items-center justify-between">
        <Link to={PATHS.signIn(appUser)} className="text-sm text-primary no-underline">
          Back to Sign In
        </Link>
        <div className="flex items-center">
          <Button className="font-normal" onClick={handleResendCode}>
            Resend Code
          </Button>
        </div>
      </div>
      <SuccessModal
        showModal={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        handleOk={() => navigate(PATHS.signIn(appUser))}
        message="Your password has been reset successfully."
      />
    </>
  );
};

export default ResetPassword;
