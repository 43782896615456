// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import CpBalances from '../../assets/CpBalances.webp';

export const Balances = () => {
  return (
    <Accordion summary="How do I see my Balances?">
      <p className="mt-0">CP balances for each case are shown in Balances</p>
      <LazyLoadImage src={CpBalances} alt="cp balance" height={350} />
    </Accordion>
  );
};
