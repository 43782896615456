// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { trimExtraSpaces } from 'src/utils/stringFormatter';

// Feature
import { AmountCell } from '../../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  monthWidth: 350,
  amountWidth: 90,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t, text_right } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ minWidth: tableProp.monthWidth }]}>Months That Payments Were Applied</Text>
    <Text style={[{ width: tableProp.amountWidth }, text_right]}>Amount</Text>
  </View>
);

const TableBody = ({ payments }) => {
  const paymentInfo = payments[0].payment_info;
  const isSinglePayment = paymentInfo.length === 1;

  if (isSinglePayment) {
    return (
      <View style={[flex_row, p_2, pl_3, border_t]} key={`payment-0`}>
        <Text style={{ minWidth: tableProp.monthWidth }}>--</Text>
        <AmountCell width={tableProp.amountWidth} amount={paymentInfo[0].pmt_amt} />
      </View>
    );
  }

  return paymentInfo.slice(0, -1).map((payment_info, index) => (
    <View style={[flex_row, p_2, pl_3, border_t]} key={`payment-${index}`}>
      <Text style={{ minWidth: tableProp.monthWidth }}>
        {trimExtraSpaces(payment_info.pay_period)}
      </Text>
      <AmountCell width={tableProp.amountWidth} amount={payment_info.pmt_amt} />
    </View>
  ));
};

const TableFooter = ({ payments }) => {
  return (
    <View style={[border_t, p_2, pl_3, flex_row]}>
      <Text style={[{ minWidth: tableProp.monthWidth }]}>
        {payments[0].payment_info.slice(-1)[0].pay_period}
      </Text>
      <AmountCell
        width={tableProp.amountWidth}
        amount={payments[0].payment_info.slice(-1)[0].pmt_amt}
      />
    </View>
  );
};

export const PaymentInfoTable = ({ payments }) => {
  if (payments[0].payment_info === null) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>Payment Information</Text>
        <Text>No payment found in the last quarter.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>Payment Information</Text>
      <TableHeader />
      <TableBody payments={payments} />
      <TableFooter payments={payments} />
    </>
  );
};
