// External
import { useParams } from 'react-router-dom';

// Project
import { Spinner } from 'src/components/Spinner';
import { CenterLayout } from 'src/layout';

// Feature
import { useStatus } from './api/getStatus';
import { StatusTable } from './components';

export const CaseStatus = () => {
  const { caseId, userIdentifier, appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const { data: status, isLoading, error } = useStatus(caseId, userIdentifier, isAppUserAgency);

  if (isLoading)
    return (
      <CenterLayout>
        <Spinner />
      </CenterLayout>
    );

  if (error) return <span>Error: {error.response?.data.message}</span>;

  return (
    <div className="w-full">
      <p className="pl-4">
        <b className="text-2xl">Case Status</b>
      </p>
      <StatusTable data={status} />
    </div>
  );
};
