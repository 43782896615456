// External
import { useParams } from 'react-router-dom';

// Feature
import { AgencySignIn } from './AgencySignIn';
import { CustomerSignIn } from './CustomerSignIn';

export const SignIn = () => {
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';

  if (isAppUserAgency) {
    return <AgencySignIn />;
  } else {
    return <CustomerSignIn />;
  }
};
