// External
import { Routes, Route } from 'react-router-dom';

// Project
import { NotFoundError } from 'src/components/Error';

// Feature
import { Faq } from './Faq';

export const FaqRoutes = () => {
  return (
    <Routes>
      <Route index element={<Faq />} />
      <Route path="*" element={<NotFoundError />} />
    </Routes>
  );
};
