// External
import { Text } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';

export const PageNumber = () => {
  const { absolute, text_m, bottom_7, left_0, right_0, text_center } = pdfStyles;

  return (
    <Text
      style={[absolute, text_m, bottom_7, left_0, right_0, text_center]}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );
};
