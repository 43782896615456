// Feature
import { Accordion } from '../shared/Accordion';
import { UnorderedList } from '../shared/List';

export const Lockout = () => {
  return (
    <Accordion summary="I got locked out from signing in/linking to my CSEA account, what should I do?">
      <UnorderedList>
        <li className="mb-3">
          For sign-in: After 3 unsuccessful sign in attempts, you will be locked out for a period of
          15 minutes. After the 15-minute restriction expires, you may try to sign in again. If you
          have forgotten your password, use the Forgot Password? link to reset your password.
        </li>
        <li>
          For linking to CSEA account: After 3 unsuccessful linking attempts (using PIN & Access
          Code), you will be locked out for a period of 15 minutes. After the 15-minute restriction
          expires, you may try to sign in again. If you have forgotten your password, use the Forgot
          Password? link to reset your password.
        </li>
      </UnorderedList>
    </Accordion>
  );
};
