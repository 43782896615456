// Feature
import { PaymentsPending } from './PaymentsPending';
import { PaymentsReceived } from './PaymentsReceived';
import { Balances } from './Balances';
import { Recoupment } from './Recoupment';
import { FederalOffset } from './FederalOffset';
import { BillStatement } from './BillStatement';

export const NonCustodialParent = () => {
  return (
    <div>
      <PaymentsPending />
      <PaymentsReceived />
      <Balances />
      <Recoupment />
      <FederalOffset />
      <BillStatement />
    </div>
  );
};
