// External
import { Outlet, useParams } from 'react-router-dom';
import { Amplify, API } from 'aws-amplify';

// Project
import { AWS_CUSTOMER_CONFIG, AWS_AGENCY_CONFIG, AWS_API_ENDPOINTS } from 'src/config';
import { MainLayout } from 'src/layout';
import { NotFoundError } from 'src/components/Error';

export const AwsConfigContainer = () => {
  const { appUser } = useParams();

  if (appUser !== 'customer' && appUser !== 'agency') {
    return (
      <MainLayout>
        <NotFoundError />
      </MainLayout>
    );
  }

  const isAppUserAgency = appUser === 'agency';
  API.configure(AWS_API_ENDPOINTS);

  if (isAppUserAgency) {
    document.title = 'CSEA Agency Portal';
    Amplify.configure(AWS_AGENCY_CONFIG);
  } else {
    document.title = 'CSEA Customer Portal';
    Amplify.configure(AWS_CUSTOMER_CONFIG);
  }

  return <Outlet />;
};
