// External
import { ReactElement } from 'react';
import { Link } from '@mui/material';

interface ExternalLinkProp {
  text: string;
  href: string;
}

export const ExternalLink = ({ text, href }: ExternalLinkProp): ReactElement => (
  <Link target="_blank" href={href} rel="noopener">
    {text}
  </Link>
);
