import { Tabs, TabsProps, styled } from '@mui/material';

const CustomSidebarNav = styled(Tabs)<TabsProps>(({ theme }) => ({
  minWidth: '225px',
  '.MuiTabs-indicator': {
    left: 0,
    display: 'none',
  },
  '.MuiTabs-flexContainerVertical': {
    alignItems: 'flex-start',
  },
}));

export const SidebarNav = ({ value, ariaLabel, children }) => {
  return (
    <CustomSidebarNav orientation="vertical" value={value} aria-label={ariaLabel}>
      {children}
    </CustomSidebarNav>
  );
};
