// External
import { useEffect, useState, useRef } from 'react';
import {
  MenuItem,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

interface TabMenuProps {
  title: string;
  activePath: string;
  activeStyle: object;
  inactiveStyle: object;
  itemList: {
    name: string;
    path: string;
  }[];
}

export function TabMenu(props: TabMenuProps) {
  let navigate = useNavigate();
  let location = useLocation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [isActive, setIsActive] = useState(false); // control styles

  useEffect(() => {
    location.pathname.includes(props.activePath) ? setIsActive(true) : setIsActive(false);
  }, [location.pathname, props.activePath]);

  const onClickItem = (event: Event | React.SyntheticEvent, path: string) => {
    handleClose(event);
    navigate(path);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        disableRipple
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        sx={isActive ? props.activeStyle : props.inactiveStyle}
        className="rounded-none"
        ref={anchorRef}
      >
        {props.title}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper sx={{ marginTop: '5px' }} elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="configuration-menu"
                  aria-labelledby="configuration-button"
                  onKeyDown={handleListKeyDown}
                >
                  {props.itemList.map((item) => (
                    <MenuItem key={item.name} onClick={(e) => onClickItem(e, item.path)}>
                      <Typography textAlign="center">{item.name}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
