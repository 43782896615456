// External
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Button } from '@mui/material';

export const getTableColumns = (
  setModalOpen: (modalOpen: boolean) => void,
  setEmail: (email: string) => void
) => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'email',
      label: 'Email',
      options: { sort: false },
    },
    {
      name: '',
      label: ' ',
      options: {
        customBodyRender: (_, meta: MUIDataTableMeta) => {
          return (
            <Button
              onClick={() => {
                setModalOpen(true);
                setEmail(meta.rowData[0]);
              }}
              variant="outlined"
              className="ml-[50%] rounded-full py-0 font-normal"
            >
              Unlock
            </Button>
          );
        },
        sort: false,
      },
    },
  ];

  return columns;
};
