// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import RecoupmentImg from '../../assets/Recoupment.webp';

export const Recoupment = () => {
  return (
    <Accordion summary="How do I view my Recoupment?">
      <p className="mt-0">
        If you have a recoupment account, information is available on the Recoupment tab.
      </p>
      <LazyLoadImage src={RecoupmentImg} alt="recoupment" height={350} />
    </Accordion>
  );
};
