// Feature
import { General } from '../components/General';
import { NonCustodialParent } from '../components/NonCustodialParent';
import { CustodialParent } from '../components/CustodialParent';

export const Faq = () => {
  return (
    <div>
      <h1 className="text-lg font-semibold">Frequently Asked Questions</h1>
      <h2 className="text-base font-semibold">General</h2>
      <General />
      <h2 className="text-base font-semibold">Non-Custodial Parent</h2>
      <NonCustodialParent />
      <h2 className="text-base font-semibold">Custodial Parent</h2>
      <CustodialParent />
    </div>
  );
};
