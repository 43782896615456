import { createContext, useMemo, useState } from 'react';

export const CognitoContext = createContext<any | null>(null);

export const CognitoProvider = ({ children }) => {
  const [cognitoObj, setCognitoObj] = useState();

  const updateCognitoObj = (obj) => {
    setCognitoObj(obj);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cognitoContext = useMemo(() => ({ cognitoObj, updateCognitoObj }), [cognitoObj]);
  return <CognitoContext.Provider value={cognitoContext}>{children}</CognitoContext.Provider>;
};
