// External
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface BackButtonProps {
  label: string;
  to?: string;
  isCustomerName?: boolean;
}

export const BackButton = ({ label, to, isCustomerName }: BackButtonProps) => {
  return (
    <Link to={to ? to : '..'} className="no-underline">
      <Button
        className={isCustomerName ? 'text-2xl font-bold' : ''}
        variant="text"
        startIcon={<ArrowBackIcon />}
        style={{ color: '#616161' }}
      >
        {label}
      </Button>
    </Link>
  );
};
