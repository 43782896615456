// External
import { Text } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';

const { mt_3, mb_3, font_bold, text_lg, leading_2 } = pdfStyles;

export const NotificationOfOIW = () => {
  return (
    <>
      <Text style={[mt_3, mb_3, font_bold, text_lg]}>
        Notification of OIW Tack-On Implementation
      </Text>
      <Text style={leading_2}>
        Beginning November 1, 2008, the agency will implement a new enforcement program to pursue
        the collection of delinquent support for cases without active current child support under
        HRS SCE.576D-14 and HRS SEC.571-52.2. Collection of past due balances will be initiated
        through the withholding of the responsible party's wages and other income. Cases without
        activities that have delinquencies in the last 10 years will be identified for action. If
        your case is selected, you will receive a notice in the mail of the action that has been
        taken. You can request a hearing if you disagree with the delinquent balances of support.
      </Text>
    </>
  );
};
