// External
import { useQuery } from '@tanstack/react-query';

// Local
import { fetchData } from 'src/lib/fetch';
import { CasesInfo, FetchApiType, IHookError } from 'src/types';

export const useCases = (pinNumber: string) => {
  let apiType: FetchApiType;
  let queryParameters: object;

  if (pinNumber) {
    apiType = 'hic';
    queryParameters = { pin: +pinNumber };
  } else {
    apiType = 'case';
  }

  const results = useQuery<CasesInfo[], IHookError>({
    queryKey: ['/case', queryParameters],
    queryFn: () => fetchData(apiType, '/case', queryParameters),
  });

  console.log('useCases', results);
  return results;
};
