// External
import { Text, Page, View, Document, usePDF } from '@react-pdf/renderer';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { useUserInfo } from 'src/hooks';
import { PageNumber } from 'src/components/Pdf';

// Feature
import { useBillStatement } from '../api';
import { AddressBox, BillStatementInfoBox } from '../components/NcpBillPaymentInfo';

const BillDocument = ({ billStatementData }) => {
  const {
    font_bold,
    text_lg,
    border_slate_600,
    mb_3,
    mb_5,
    mb_6,
    border_l,
    border_r,
    border_b,
    border_t_2,
    p_3,
    text_center,
  } = pdfStyles;

  return (
    <Document>
      <Page size="LETTER" style={pdfStyles.pageDefault}>
        <Text style={[mb_6, text_lg, font_bold]}>Child Support Enforcement Agency</Text>
        <AddressBox />
        <BillStatementInfoBox billStatementData={billStatementData} />
        <View style={[text_center, mb_5]}>
          <Text style={font_bold}>Payments will be applied to all cases of the Payor</Text>
        </View>
        <View style={[mb_3]}>
          <Text>PLEASE INDICATE BELOW ANY CHANGES SINCE YOUR LAST STATEMENT</Text>
        </View>
        <View style={[p_3, border_t_2, border_r, border_l, border_slate_600]}>
          <Text>New Telephone</Text>
        </View>
        <View style={[p_3, border_t_2, border_r, border_l, border_slate_600]}>
          <Text>New Address</Text>
          <Text> </Text>
          <Text> </Text>
          <Text> </Text>
        </View>
        <View style={[p_3, border_t_2, border_r, border_l, border_b, border_slate_600]}>
          <Text>New Email</Text>
        </View>
        <PageNumber />
      </Page>
    </Document>
  );
};

export const useBillPaymentInfo = (caseId: string, pin: string) => {
  const location = useLocation();
  const { appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const path = location.pathname;
  let isOnBillStatementPage = path.split('/').pop().includes('bill-statement');

  // Data
  const {
    data: billStatementData,
    isLoading: apStatementIsLoading,
    error: apStatementError,
  } = useBillStatement(caseId);
  const { isLoading: userIsLoading, error: userError } = useUserInfo(pin, isAppUserAgency);
  const isLoading = apStatementIsLoading || userIsLoading;
  const hasError = apStatementError || userError;
  const isDataEmpty = !billStatementData || !billStatementData[0].mailing_info;

  const shouldRenderPdf = isLoading || hasError || isDataEmpty;

  const [instance, update] = usePDF({
    document: shouldRenderPdf ? null : <BillDocument billStatementData={billStatementData} />,
  });

  useEffect(() => {
    update(shouldRenderPdf ? null : <BillDocument billStatementData={billStatementData} />);
  }, [billStatementData, shouldRenderPdf, update]);

  let result = {
    displayBillPaymentInfo: isOnBillStatementPage,
    data: billStatementData,
    report: {
      href: instance.url,
      download: 'bill-payment-info.pdf',
    },
    error: apStatementError || userError,
  };

  console.log('useBillPaymentInfo', result);
  return result;
};
