// External
import { Link, Outlet, useParams, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

// Project
import { BackButton } from 'src/components/Button';
import { CaseNavBar } from 'src/components/Navigation';
import { Spinner } from 'src/components/Spinner';
import PATHS from 'src/config/paths';
import { useCases } from 'src/hooks';

// Layout
import { CenterLayout } from './CenterLayout';

export const CaseLayout = () => {
  const { userIdentifier, appUser } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const pinNumber = isNaN(+userIdentifier) ? '' : userIdentifier;
  const { data: casesData, isLoading: caseIsLoading, error: caseError } = useCases(pinNumber);
  const location = useLocation();
  const path = location.pathname;
  const isPaymentInfoPath = path.includes('/payment-info');

  if (caseIsLoading) {
    return (
      <CenterLayout>
        <div>
          <p>Loading cases</p>
          <div className="flex justify-center">
            <Spinner />
          </div>
        </div>
      </CenterLayout>
    );
  }

  if (caseError) {
    throw caseError;
  }

  return (
    <div>
      {!isPaymentInfoPath && (
        <span className="align-top">
          <BackButton
            label={isAppUserAgency ? casesData[0].user_name + ' - PIN ' + pinNumber : 'MY CASES'}
            to={isAppUserAgency ? PATHS.agencyCases(pinNumber) : PATHS.customerCases}
            isCustomerName={isAppUserAgency}
          />
        </span>
      )}
      {!isPaymentInfoPath && casesData[0].cases.some((cs) => cs.ncp === casesData[0].user_name) ? (
        <Link
          to={
            isAppUserAgency ? PATHS.agencySupportBalance(pinNumber) : PATHS.customerSupportBalance
          }
        >
          <Button
            variant="contained"
            className={`mb-3 ml-2 rounded-full${isAppUserAgency ? ' mt-1' : ''}`}
          >
            View Support Balance
          </Button>
        </Link>
      ) : null}
      {!isPaymentInfoPath && <CaseNavBar cases={casesData?.[0].cases} />}
      <Outlet />
    </div>
  );
};
