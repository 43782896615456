// External
import { ReactElement } from 'react';
import { Stack, Grid } from '@mui/material';

const ABOUT_US_DATA = [
  {
    label: 'Contact Us',
    href: 'https://ag.hawaii.gov/csea/contact/',
  },
  {
    label: 'Site Map',
    href: 'https://ag.hawaii.gov/site-map/',
  },
];

const POLICIES_DATA = [
  {
    label: 'Terms of Use',
    href: 'https://portal.ehawaii.gov/terms-of-use.html',
  },
  {
    label: 'Accessibility',
    href: 'https://portal.ehawaii.gov/accessibility.html',
  },
  {
    label: 'Privacy Policy',
    href: 'https://portal.ehawaii.gov/privacy-policy.html',
  },
];

const Link = ({ label, href }) => (
  <a
    className="p-0 text-sm text-white/95 no-underline hover:underline"
    target="_blank"
    rel="noreferrer"
    href={href}
  >
    {label}
  </a>
);

const FooterItem = ({ title, linkList }) => (
  <Stack>
    <div className="pb-3 font-bold">{title}</div>
    {linkList.map(({ label, href }, key) => (
      <Link label={label} href={href} key={key} />
    ))}
  </Stack>
);

export const Footer = (): ReactElement => {
  return (
    <div className="bg-black/60 p-5 text-white/95">
      <Grid container spacing={3}>
        <Grid item>
          <FooterItem title="ABOUT US" linkList={ABOUT_US_DATA} />
        </Grid>
        <Grid item>
          <FooterItem title="POLICIES" linkList={POLICIES_DATA} />
        </Grid>
        <Grid item className="ml-auto">
          <img alt="tate of Hawaii Seal no color" src="/footer-seal.png" className="max-h-28" />
        </Grid>
      </Grid>
      <div className="flex justify-end pt-3">
        Copyright © 2022, State of Hawaii. All rights reserved.
      </div>
    </div>
  );
};
