// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';

// Feature
import { AmountCell } from '../PDF/AmountCell';

const tableProp = {
  // Header widths
  amountWidth: 120,
  amountRightPadding: 30,
  receivedWidth: 160,
};

const { font_bold, text_m, pb_4, flex_row, pl_3, pb_3, p_2, border_t } = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_3, pb_3]}>
    <Text style={[{ minWidth: tableProp.amountWidth }, font_bold]}>Payment Received</Text>
    <Text style={[{ minWidth: tableProp.receivedWidth }, font_bold]}>Received Date</Text>
  </View>
);

const TableBody = ({ payments }) => {
  return payments.map((payment, index) => (
    <View key={index} style={[flex_row, p_2, border_t]}>
      <AmountCell
        width={tableProp.amountWidth}
        paddingRight={tableProp.amountRightPadding}
        amount={payment.pmt_amt}
      />
      <Text style={{ minWidth: tableProp.receivedWidth }}>
        {formatDate(payment.date_recd, 'YYYYMMDD', 'MMMM D, YYYY')}
      </Text>
    </View>
  ));
};

export const PaymentsPendingTable = ({ payments }) => {
  if (payments === undefined || payments.length === 0) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>Payments Pending</Text>
        <Text>There are currently no pending payments for this case.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>Payments Pending</Text>
      <TableHeader />
      <TableBody payments={payments} />
    </>
  );
};
