// External
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';
import { isValidEmail } from 'src/utils/email';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { appUser } = useParams();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setError('');

    if (!isValidEmail(email)) {
      setError('Invalid email');
      return;
    }

    try {
      await Auth.forgotPassword(email);
      navigate(PATHS.resetPassword(appUser), { state: { email } });
    } catch (err) {
      console.log('ForgotPassError', err);
      if (err.name === 'LimitExceededException') {
        setError('Too many requests. Please try again later.');
      } else {
        setError('System error. Please try again later.');
      }
    }
  };

  return (
    <>
      <h1 className="text-center">Reset Your Password</h1>
      <p className="mb-3">
        Enter the email associated with this account. A code will be sent to reset your password.
      </p>
      <p className="text-red-600">Note: Resetting your password will not unlock your account.</p>
      <form onSubmit={handleOnSubmit}>
        <TextInput
          autoComplete="off"
          label="Email"
          value={email}
          placeholder="Email"
          required
          onChange={(e) => setEmail(e.target.value)}
          error={error !== ''}
        />
        {error !== '' && <span className="text-red-600">{error}</span>}

        <Button variant="contained" className="mt-7 w-full normal-case" type="submit">
          Send code
        </Button>
      </form>
      <Link
        to={PATHS.signIn(appUser)}
        className="mt-5 flex justify-center text-primary no-underline"
      >
        Back to Sign In
      </Link>
    </>
  );
};

export default ForgotPassword;
