export const HeaderUam = () => {
  return (
    <div className="flex gap-5 justify-center items-center">
      <img className="h-24" alt="State of Hawaii Seal" src="/seal_of_hawaii.png" />
      <div>
        <p className="mb-0 text-sm">State of Hawaii</p>
        <p className="m-0 font-bold">Child Support Enforcement Agency</p>
      </div>
    </div>
  );
};
