// Project
import { LazyLoadImage } from 'src/components/Image';
import { ExternalLink } from 'src/components/Link';
import { CSEA_HOMEPAGE_URL } from 'src/config';

// Feature
import { Accordion } from '../shared/Accordion';
import CustomerSignIn from '../../assets/CustomerSignIn.webp';
import CseaHomePage from '../../assets/CseaHomePage.webp';
import { OrderList } from '../shared/List';

export const Login = () => {
  return (
    <Accordion summary="How do I login to the account?">
      <OrderList>
        <li className="ml-5">
          Access the CSEA Customer Portal via the CSEA Home Page (
          <ExternalLink href={CSEA_HOMEPAGE_URL} text={CSEA_HOMEPAGE_URL} />
          ).
        </li>
        <li className="ml-5">Click My Account button.</li>
      </OrderList>
      <LazyLoadImage src={CseaHomePage} alt="Home Page My Account" height={600} className="mb-4" />
      <LazyLoadImage src={CustomerSignIn} alt="Customer Sign In" height={600} />
    </Accordion>
  );
};
