// External
import { useQuery } from '@tanstack/react-query';

// Project
import { getHicData } from 'src/lib/fetch';
import { IHookError } from 'src/types';

export const useHicData = <T>(path: string, queryParameters: object, options?: any) => {
  const results = useQuery<T, IHookError>({
    queryKey: ['hic', path, queryParameters],
    queryFn: () => getHicData(path, queryParameters),
    ...options,
  });

  console.log('useHicData', results);
  return results;
};
