// Project
import { ExternalLink } from 'src/components/Link';
import { CSEA_ENFORCEMENTS_URL } from 'src/config';

// Feature
import { IEnforcement } from '../types';

interface FooterProps {
  data: IEnforcement;
  isFlagOn: boolean;
}

const DefaultEnforcementFooter = () => {
  return (
    <p>
      License Suspension, Unemployment Insurance Benefits and other enforcement actions are not
      displayed and may have been initiated. For additional information,{' '}
      <ExternalLink
        text="read more about CSEA’s enforcement remedies"
        href={CSEA_ENFORCEMENTS_URL}
      />
      .
    </p>
  );
};

const LicenseSuspensionFooter = () => {
  return (
    <p>
      Unemployment Insurance Benefits and other enforcement actions are not displayed and may have
      been initiated. For additional information,{' '}
      <ExternalLink
        text="read more about CSEA’s enforcement remedies"
        href={CSEA_ENFORCEMENTS_URL}
      />
      .
    </p>
  );
};

// NOTE: only one date should be present at any time, but if any are available, then
// show LicenseSuspensionFooter
export const Footer = ({ data, isFlagOn }: FooterProps) => {
  if (
    isFlagOn === true &&
    (data.notice_referral_date > 0 || data.notice_cert_date > 0 || data.release_date > 0)
  ) {
    return <LicenseSuspensionFooter />;
  }

  return <DefaultEnforcementFooter />;
};
