// External
import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

// Project
import { ProtectedRoute, SuspenseContainer } from 'src/containers';

// Feature
import { UamLayout } from '../layout';
import { ContainerUAM } from '../containers';
import { SignIn } from './SignIn';
import SmsMfa from './SmsMfa';
import TotpMfa from './TotpMfa';
import SetupTotp from './SetupTotp';
import LinkKeiki from './LinkKeiki';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerifySms from './VerifySms';
import VerifyEmail from './VerifyEmail';
import PinCheck from './PinCheck';
import NoPinAccessCode from './NoPinAccessCode';
import TermsConditions from './TermsConditions';
import { OnVpn } from './OnVpn';

// Lazy loading because react-phone-number-input is a large library
const CreateAccount = lazy(() =>
  import(/* webpackChunkName: "create-account-page" */ './CreateAccount').then((module) => ({
    default: module.CreateAccount,
  }))
);

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<UamLayout />}>
        <Route element={<ContainerUAM />}>
          <Route element={<SuspenseContainer />}>
            <Route path="create-account" element={<CreateAccount />} />
          </Route>
          <Route path="pin-check" element={<PinCheck />} />
          <Route path="no-pin-access-code" element={<NoPinAccessCode />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verify-sms" element={<VerifySms />} />
          <Route path="mfa" element={<SmsMfa />} />
          <Route path="terms-conditions" element={<TermsConditions />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route
            path="link-keiki"
            element={
              <ProtectedRoute>
                <LinkKeiki />
              </ProtectedRoute>
            }
          />
          {/* Agency specific */}
          <Route path="on-vpn" element={<OnVpn />} />
          <Route path="totp" element={<TotpMfa />} />
          <Route path="totp-setup" element={<SetupTotp />} />
        </Route>
      </Route>
    </Routes>
  );
};
