// External
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';
import { toCurrency } from 'src/utils/stringFormatter';

// Feature
import { getBillStatementBalanceDue } from '../../utils/payments';

export const BillStatementInfoBox = ({ billStatementData }) => {
  const {
    flex_row,
    font_bold,
    border_slate_600,
    mb_3,
    border_l,
    border_r,
    border_b,
    border_t_2,
    p_3,
    text_center,
    w_50,
    w_33,
  } = pdfStyles;

  const caseInfo = billStatementData[0].mailing_info[0];

  return (
    <>
      <View style={[flex_row]}>
        <View style={[text_center, p_3, border_l, border_t_2, border_r, w_50, border_slate_600]}>
          <Text>
            NCP Name: <Text style={font_bold}>{caseInfo.ap_name}</Text>
          </Text>
        </View>
        <View style={[text_center, p_3, border_t_2, border_r, w_50, border_slate_600]}>
          <Text>
            NCP PIN: <Text style={font_bold}>{caseInfo.ap_pin}</Text>
          </Text>
        </View>
      </View>
      <View style={[flex_row, mb_3]}>
        <View
          style={[
            text_center,
            p_3,
            border_l,
            border_t_2,
            border_b,
            border_r,
            w_33,
            border_slate_600,
          ]}
        >
          <Text>Statement as of</Text>
          <Text style={font_bold}>
            {formatDate(
              billStatementData[0].statement_date[0].as_of_date,
              'YYYY-MM-DD',
              'MMMM D, YYYY'
            )}
          </Text>
        </View>
        <View style={[text_center, p_3, border_t_2, border_r, border_b, w_33, border_slate_600]}>
          <Text>Balance Due</Text>
          <Text style={font_bold}>
            $ {toCurrency(getBillStatementBalanceDue(billStatementData))}
          </Text>
        </View>
        <View
          style={[
            text_center,
            p_3,
            border_t_2,
            border_r,
            border_b,
            { width: '34%' },
            border_slate_600,
          ]}
        >
          <Text>Amount Enclosed</Text>
          <Text style={font_bold}>$_______________</Text>
        </View>
      </View>
    </>
  );
};
