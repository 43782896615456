// Project
import { getEmail } from 'src/lib/auth';

export const setLocalAppUser = async (appUser: string) => {
  const email = await getEmail();
  localStorage.setItem(`CseaAppUser.${email}`, appUser);
};

export const getLocalAppUser = async () => {
  const email = await getEmail();
  return localStorage.getItem(`CseaAppUser.${email}`);
};

export const clearLocalAppUser = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key.startsWith('CseaAppUser.')) {
      localStorage.removeItem(key);
    }
  }
};

export const doesALocalAppUserExist = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith('CseaAppUser.')) {
      return true;
    }
  }
  return false;
};
