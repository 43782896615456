// External
import { Button, Dialog, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ComponentProps {
  showModal: boolean;
  handleClose: () => void;
  handleOk: () => void;
  message: string;
}

export const SuccessModal = ({ showModal, handleClose, handleOk, message }: ComponentProps) => {
  return (
    <Dialog open={showModal}>
      <Paper className="w-[50vw] max-w-[inherit]">
        <div className="border-x-0 border-t-0 border-b-[1px] border-solid border-[#E0E0E0] p-6">
          <IconButton className="absolute top-4 right-3" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div className="flex items-center gap-2">
            <CheckCircleIcon aria-label="Success" className="fill-green-600" />
            <h2 className="m-0 text-lg font-semibold">Message</h2>
          </div>
          <p className="ml-8">{message}</p>
        </div>
        <div className="flex justify-center py-3">
          <Button variant="contained" onClick={handleOk}>
            OK
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
};
