// External
import { MUIDataTableOptions } from 'mui-datatables';

export const getTableOptions = (
  totalPages: number,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
) => {
  const ROWS_PER_PAGE = 10;

  const options = {
    elevation: 0,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: 'none',
    expandableRowsHeader: false,
    count: totalPages * ROWS_PER_PAGE,
    pagination: true,
    page: page,
    rowsPerPage: ROWS_PER_PAGE,
    rowsPerPageOptions: [],
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setPage(tableState.page);
      }
    },
  } as MUIDataTableOptions;

  return options;
};
