export enum FormCodes {
  FFI902 = 'Statement of Account Balance',
  FFI917 = 'Direct Deposit Authorization',
  FFI951 = 'Payments and Disbursement Details',
  PCS002 = 'Abbreviated Application for IV-D Services Packet',
  FFI953 = 'Checklist',
  FCS002 = 'Approval of Authorized Representation',
  FEN005 = 'Request for Expedited Release of Joint Tax Refund',
  FMG026 = 'Request for Closure Form',
  FMG033 = 'Intact Family Status Claim Request and Termination',
}
