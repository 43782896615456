// Feature
import { Balances } from './Balances';
import { CpStatement } from './CpStatement';
import { Disbursements } from './Disbursements';
import { PaymentsApplied } from './PaymentsApplied';
import { Recoupment } from './Recoupment';

export const CustodialParent = () => {
  return (
    <div>
      <PaymentsApplied />
      <Disbursements />
      <Balances />
      <Recoupment />
      <CpStatement />
    </div>
  );
};
