// External
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Project
import { formatDate } from 'src/lib/date';
import { toCurrency } from 'src/utils/stringFormatter';
import SampleCheck from 'src/assets/images/sample-check.png';

// Feature
import { Row } from '../Row';
import { getBillStatementBalanceDue } from '../../utils/payments';

export const PaymentInfo = ({ caseId, data, report }) => {
  return (
    <>
      <Button
        variant="text"
        href="bill-statement"
        startIcon={<ArrowBackIcon />}
        style={{ color: '#616161' }}
      >
        BILL STATEMENT
      </Button>
      <div className="flex justify-between">
        <h2 className="mb-8 text-2xl font-bold">Case {caseId}</h2>
        <div className="mb-1 flex items-center">
          <Button variant="contained" href={report.href} download={report.download}>
            Print Voucher
          </Button>
        </div>
      </div>
      <div className="w-1/3">
        <div className="mb-3 grid grid-cols-2 gap-2">
          <Row
            label="Statement as of"
            value={formatDate(data[0].statement_date[0].as_of_date, 'YYYY-MM-DD', 'MMMM D, YYYY')}
          />
        </div>
        <div className="mb-3 grid grid-cols-2 gap-2">
          <Row label="Name" value={data[0].mailing_info[0].ap_name} />
        </div>
        <div className="mb-3 grid grid-cols-2 gap-2">
          <Row label="Custodial Parent" value={data[0].mailing_info[0].cp_name} />
        </div>
        <div className="mb-3 grid grid-cols-2 gap-2">
          <Row label="Balance Due" value={`$ ${toCurrency(getBillStatementBalanceDue(data))}`} />
        </div>
      </div>
      <div className="mb-12"></div>
      <p>
        Make your check payable to: <b>Child Support Enforcement Agency</b>
      </p>
      <p>
        Be sure to include your PIN in the "For" or "Memo" field in the check's lower left corner.
      </p>
      <img alt="Sample Check" src={SampleCheck} style={{ width: 350 }} />
      <div className="mt-9 mb-16 grid w-2/3 grid-cols-3 gap-2">
        <div className="col-span-1">Mail your completed check to:</div>
        <div className="col-span-2">
          <span className="font-bold">Child Support Enforcement Agency</span>
          <br />
          <span className="font-bold">P.O. Box 1860</span>
          <br />
          <span className="font-bold">Honolulu, HI&nbsp; 96805-1860</span>
        </div>
      </div>
    </>
  );
};
