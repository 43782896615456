// External
import { useContext, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { QRCodeCanvas } from 'qrcode.react';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';
import { signOut } from 'src/lib/auth';

// Features
import { CognitoContext } from '../providers';

const SetupTotp = () => {
  const navigate = useNavigate();
  const { cognitoObj, updateCognitoObj } = useContext(CognitoContext);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [qrContent, setQrContent] = useState(null);

  useEffect(() => {
    Auth.setupTOTP(cognitoObj).then((code) => {
      setQrContent(
        'otpauth://totp/AWSCognito:' + encodeURIComponent(cognitoObj.username) + '?secret=' + code
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async (event) => {
    try {
      event.preventDefault();
      Auth.verifyTotpToken(cognitoObj, code)
        .then(() => {
          updateCognitoObj(undefined);
          navigate(PATHS.onVpn);
        })
        .catch((e) => {
          console.log('verifyTotpTokenError', e);
        });
    } catch (err) {
      console.log('confirmError', err);
      setError('Invalid code, please enter again.');
    }
  };

  const handleOnChange = (e) => {
    setCode(e.target.value);
  };

  const handleOnBackToSignIn = () => {
    signOut();
  };

  return (
    <>
      <h1 className="text-center">Confirm MFA Code</h1>
      {qrContent ? (
        <div className="flex justify-center">
          <QRCodeCanvas value={qrContent} />
        </div>
      ) : null}
      <p className="mb-3">Please enter the code from Authenticator: 2FA Client</p>
      <form onSubmit={handleConfirm}>
        <TextInput
          name="code"
          label="Code"
          value={code}
          placeholder="Authentication Code"
          required
          onChange={handleOnChange}
          error={error !== ''}
        />
        {error && <div className="text-red-600">{error}</div>}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Confirm
        </Button>
      </form>
      <div className="mt-5 flex justify-between">
        <Link
          to={PATHS.signIn('agency')}
          onClick={handleOnBackToSignIn}
          className="text-primary no-underline"
        >
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

export default SetupTotp;
