// External
import { ReactElement, useState, useRef } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useIdleTimer } from 'react-idle-timer';

// Project
import PATHS from 'src/config/paths';
import { signOut } from 'src/lib/auth';
import { MainLayout } from 'src/layout';
import { NotAuthorizedError } from 'src/components/Error';
import { useLocalAppUser } from 'src/hooks';

interface ComponentProps {
  children?: ReactElement;
}

interface ModalProps {
  showDialog: boolean;
  showCloseIcon: boolean;
  title: string;
  message: string;
  handleClose: () => void;
  handleOk: () => void;
}

const SessionExpiredModal = ({
  showDialog,
  showCloseIcon,
  message,
  title,
  handleClose,
  handleOk,
}: ModalProps) => {
  return (
    <Dialog open={showDialog}>
      <Paper className="w-[50vw] max-w-[inherit]">
        <div className="border-x-0 border-b-[1px] border-t-0 border-solid border-[#E0E0E0] p-6">
          {showCloseIcon ? (
            <IconButton className="absolute right-3 top-4" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          ) : null}
          <div className="flex items-center gap-2">
            <ErrorRoundedIcon aria-label="Warning" className="fill-orange-600" />
            <h2 className="m-0 text-lg font-semibold">{title}</h2>
          </div>
          <p className="ml-8">{message}</p>
        </div>
        <div className="flex justify-center py-3">
          <Button variant="contained" onClick={handleOk}>
            OK
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
};

export const ProtectedRoute = ({ children }: ComponentProps) => {
  const { appUser } = useParams();
  const { localAppUser } = useLocalAppUser();

  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [isTimedOut, setIsTimedOut] = useState(false);
  const interval = useRef(null);

  const INACTIVITY_LIMIT = 15 * 60 * 1000; // 15 minutes
  const WARNING_LIMIT = 2 * 60 * 1000; // 2 minutes
  let navigate = useNavigate();

  const onIdle = () => {
    console.log(`idle at ${formatDate(new Date())}`);
    pause();
    setShowDialog(true);
    setDialogTitle('Expired Session');
    setDialogMessage('Your session has expired. Please click OK to log in again.');
    signOut();
    setIsTimedOut(true);
  };

  const onAction = () => {
    // console.log(`active at ${formatDate(new Date())}`);
  };

  const onPrompt = () => {
    console.log(`prompt at ${formatDate(new Date())}`);
    setShowDialog(true);
    setDialogTitle('Session about to expire');
    setDialogMessage(getDialogMessage(WARNING_LIMIT / 1000));
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      const remaining = Math.round(getRemainingTime() / 1000);
      console.log(`${remaining}s remaining at ${formatDate(new Date())}`);
      if (remaining <= 0) {
        clearInterval(interval.current);
      } else {
        setDialogMessage(getDialogMessage(remaining));
      }
    }, 1000);
  };

  const onMessage = (data: string) => {
    console.log('message', data);
    setShowDialog(false);
    activate();
    clearInterval(interval.current);
  };

  const { getRemainingTime, activate, pause, message } = useIdleTimer({
    onIdle,
    onAction,
    onPrompt,
    onMessage,
    timeout: INACTIVITY_LIMIT,
    promptBeforeIdle: WARNING_LIMIT,
    throttle: 1000,
    crossTab: true,
    syncTimers: 200,
  });

  const handleModalClose = () => {
    message('modal-closed', true);
  };

  if (localAppUser === appUser) {
    return (
      <>
        <SessionExpiredModal
          showDialog={showDialog}
          showCloseIcon={!isTimedOut}
          message={dialogMessage}
          title={dialogTitle}
          handleClose={handleModalClose}
          handleOk={() => {
            if (isTimedOut) {
              navigate(PATHS.signIn(appUser));
            } else {
              handleModalClose();
            }
          }}
        />
        {children ? children : <Outlet />}
      </>
    );
  } else {
    return (
      <MainLayout>
        <NotAuthorizedError />
      </MainLayout>
    );
  }
};

const formatDate = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

const getDialogMessage = (seconds: number) =>
  `For security reasons, inactive session will be closed in ${seconds} seconds. Please click OK to continue session.`;
