// External
import { Outlet, useParams } from 'react-router-dom';

export const AgencyOnlyRoute = () => {
  const { appUser } = useParams();

  if (appUser === 'agency') {
    return <Outlet />;
  }

  throw new Error('NotAuthorized');
};
