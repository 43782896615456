// External
import { Tab, TabProps, ButtonBaseProps } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

// Project
import { PRIMARY_COLOR } from 'src/themes/global-constants';

type TabNavLinkProps = TabProps & NavLinkProps & ButtonBaseProps;
const CustomTabNavLink: React.FC<TabNavLinkProps> = (props) => <Tab {...props} />;

const activeStyle = {
  color: 'white',
  background: PRIMARY_COLOR,
  opacity: 1,
  width: '90%',
  margin: '3px',
  alignItems: 'start',
};

const inactiveStyle = {
  color: 'black',
  background: '#F5F5F5',
  opacity: 1,
  width: '90%',
  margin: '3px',
  alignItems: 'start',
};

export const SidebarNavLink = ({ label, value }) => (
  <CustomTabNavLink
    label={label}
    value={value}
    to={value}
    LinkComponent={NavLink}
    style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
    disableRipple
  />
);
