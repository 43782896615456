// External
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { API } from 'aws-amplify';

// Project
import * as Auth from 'src/lib/auth';
import PATHS from 'src/config/paths';

export const OnVpn = (): React.ReactElement => {
  const navigate = useNavigate();
  const [showVpnError, setShowVpnError] = useState(false);

  const isOnVpn = async () => {
    try {
      let token = await Auth.getToken();
      let res = await API.get('hic', '/health_check', {
        headers: {
          Authorization: token,
        },
      });

      if (res?.message === 'ok') {
        navigate(PATHS.agencySearch);
      } else {
        throw Error('Not on vpn');
      }
    } catch (err) {
      console.log('is not on VPN error', err.response);
      if (err) {
        setShowVpnError(true);
      }
    }
  };

  useEffect(() => {
    isOnVpn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showVpnError) {
    return (
      <>
        <Box className="flex flex-col items-center justify-center gap-7">
          <div className="text-center">
            <h2>Invalid network</h2>
            <p>Please connect to CSEA network.</p>
          </div>
          <Link
            to={PATHS.signIn('agency')}
            className="mt-5 flex justify-center text-primary no-underline"
          >
            Back to Sign In
          </Link>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box className="flex flex-col items-center justify-center gap-7">
        <div className="text-center">
          <h2>Checking Network Connection</h2>
          <p>Please wait</p>
        </div>
        <CircularProgress />
      </Box>
    </>
  );
};
