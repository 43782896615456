// External
import { ReactNode, useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, Auth } from 'aws-amplify';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

// Project
import PATHS from 'src/config/paths';
import { TextInput, PasswordInput, Checkbox } from 'src/components/Form';
import { useLocalAppUser } from 'src/hooks';
import { SIGN_IN_TERMS } from 'src/config/messages';
import { ExternalLink } from 'src/components/Link';
import { CSEA_CUSTOMER_SERVICE_URL, CSEA_HOMEPAGE_URL } from 'src/config';

// Feature
import { CognitoContext } from '../../providers';

const DEFAULT_FORM_ERROR_STATE = {
  email: false,
  password: false,
};

export const CustomerSignIn = () => {
  const navigate = useNavigate();
  const { appUser } = useParams();
  const { setLocalAppUser } = useLocalAppUser();
  const { updateCognitoObj } = useContext(CognitoContext);

  const [form, setForm] = useState({
    email: '',
    password: '',
    termsAndConditions: false,
  });
  const [formErrors, setFormErrors] = useState(DEFAULT_FORM_ERROR_STATE);
  const [error, setError] = useState<ReactNode>();
  const [loading, setLoading] = useState(false);

  const referrers = [
    'https://ag.hawaii.gov/csea/',
    'https://ag.hawaii.gov/',
    'https://csea.ehawaii.gov/iwa/index.html',
  ];
  const target = 'https://ag.hawaii.gov/csea/';
  const env = process.env.REACT_APP_ENV;
  if (env === 'prod' && !referrers.includes(document.referrer)) {
    window.location.href = target;
    return null;
  }

  const resetFormErrors = () => {
    setFormErrors(DEFAULT_FORM_ERROR_STATE);
    setError('');
  };

  const updateFormErrorState = (error: string) => {
    setFormErrors((prev) => ({ ...prev, [error]: true }));
  };

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setForm({ ...form, [name]: newValue });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    resetFormErrors();

    const { email, password } = form;
    try {
      // tracking user's input email for cognito pre-authentication trigger
      let clientMetadata = {
        email: email,
      };
      let user = await Auth.signIn(email, password, clientMetadata);
      setLoading(false);

      const env = process.env.REACT_APP_ENV;
      if (env === 'dev') {
        const currentUser = await Auth.currentAuthenticatedUser();
        const emailVerified = currentUser.attributes.email_verified;
        if (!emailVerified) {
          navigate(PATHS.verifyEmail);
        } else {
          // Successfully signed in
          const user = appUser === 'customer' ? 'customer' : 'agency';
          setLocalAppUser(user);
          navigate(PATHS.linkKeiki);
          toast.info('MFA temporarily disabled.');
        }
      } else {
        updateCognitoObj(user);
        let key = uuidv4();
        const encryptedPassword = CryptoJS.AES.encrypt(password, key).toString();
        sessionStorage.setItem(`encrypted-code.${email}`, encryptedPassword);
        sessionStorage.setItem(`encrypt-key.${email}`, key);
        navigate(PATHS.smsMFA);
      }
    } catch (err) {
      console.log('SignInError', err);
      switch (err.code) {
        case 'NotAuthorizedException':
          // try {
          //   let response = await API.get('case', `/signin-lockout-status/${email}`, {});
          //   if (response.message.locked) {
          //     setError(<LockoutMessage />);
          //   } else {
          //     setError(
          //       'Incorrect email or password. After 3 unsuccessful sign in attempts, you will ' +
          //         'be locked out for a period of 15 minutes.'
          //     );
          //   }
          // } catch (statusErr) {
          //   console.log('GetLockoutStatusError', statusErr);
          //   setError('System error. Please try again later.');
          // }
          setError(INCORRECT_EMAIL_OR_PASSWORD);
          updateFormErrorState('email');
          updateFormErrorState('password');
          break;
        case 'UserNotFoundException':
          setError('Email does not exist. Please try again.');
          updateFormErrorState('email');
          break;
        case 'UserNotConfirmedException':
          navigate(PATHS.verifySMS, { state: { email } });
          break;
        case 'UserLambdaValidationException':
          const incorrectEmailOrPasswordRegex = /Incorrect email or password/;
          const lockedOutRegex = /User (\S+) is being locked out/;
          if (err.message.match(lockedOutRegex)) {
            setError(<LockoutMessage />);
          } else if (err.message.match(incorrectEmailOrPasswordRegex)) {
            setError(INCORRECT_EMAIL_OR_PASSWORD);
          } else {
            setError('System error. Please try again later.');
          }
          break;
        default:
          setError('System error. Please try again later.');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl">Sign In</h1>
      <form onSubmit={handleSignIn} id="sign-in-form">
        <TextInput
          autoComplete="off"
          name="email"
          label="Email"
          value={form.email}
          placeholder="Email"
          required
          onChange={handleOnChange}
          error={formErrors.email}
          className="mb-5"
        />
        <PasswordInput
          autoComplete="off"
          name="password"
          label="Password"
          value={form.password}
          placeholder="Password"
          required
          onChange={handleOnChange}
          error={formErrors.password}
        />
      </form>
      {error && <div className="my-1 text-red-600">{error}</div>}
      <div className="mt-1">
        <Link to={PATHS.forgotPassword(appUser)} className="text-primary no-underline">
          Forgot Password?
        </Link>
      </div>
      <Checkbox
        checked={form.termsAndConditions}
        onChange={handleOnChange}
        name="termsAndConditions"
        label="I agree with the Terms and Conditions."
      />
      <p className="m-0 text-sm">{SIGN_IN_TERMS}</p>
      <LoadingButton
        variant="contained"
        className="mt-4 w-full normal-case"
        form="sign-in-form"
        type="submit"
        loading={loading}
        disabled={!form.termsAndConditions}
      >
        <span>Sign In</span>
      </LoadingButton>
      <div className="mt-5 flex justify-center">
        New to CSEA?&nbsp;
        <Link to={PATHS.pinCheck} className="text-primary no-underline">
          Create an Account
        </Link>
      </div>
    </>
  );
};

const LockoutMessage = () => (
  <>
    You have reached the maximum attempts to Sign In and will be locked out for a period of 15
    minutes. For questions or issues regarding how to sign in, please contact CSEA{' '}
    <ExternalLink text="Customer Service." href={CSEA_CUSTOMER_SERVICE_URL} />
  </>
);

const INCORRECT_EMAIL_OR_PASSWORD =
  'Incorrect email or password. After 3 unsuccessful sign in attempts, you will be locked out for a period of 15 minutes.';
