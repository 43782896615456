// External
import {
  Accordion as MuiAccordion,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  AccordionDetails,
  styled,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const BaseMuiAccordion = styled((props: MuiAccordionProps) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
    '&:not(:first-child)': {
      borderTop: 0,
    },
    // NOTE: MUI adds a ::before on all div's after the first div is which creates the
    // illusion of a border top
    '&::before': {
      backgroundColor: '#E0E0E0',
    },
    // No border top when expanded bc of gutter
    // add border top back in
    '&.Mui-expanded': {
      borderTop: '1px solid #E0E0E0',
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '1.2rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '30px',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '10px 0px 10px 30px',
  },
}));

interface AccordionProps {
  summary: string;
  children: React.ReactNode;
}

export const Accordion = ({ summary, children }: AccordionProps) => {
  return (
    <BaseMuiAccordion>
      <AccordionSummary>{summary}</AccordionSummary>
      <AccordionDetails className="px-16 pt-0">{children}</AccordionDetails>
    </BaseMuiAccordion>
  );
};
