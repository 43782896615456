// External
import { TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

interface ComponentProps {
  name?: string;
  label: string;
  value: string;
  placeholder: string;
  required: boolean;
  onChange: any;
  error: boolean;
  className?: string;
  autoComplete?: string;
}

export const TextInput = ({
  name = 'TextInput',
  label,
  value,
  placeholder,
  required,
  onChange,
  error,
  className,
  autoComplete,
}: ComponentProps) => {
  let id = `${label.replaceAll(' ', '')}-${uuidv4()}`;
  return (
    <div className={className}>
      <label htmlFor={id} className="mb-1 inline-block font-semibold">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <TextField
        name={name}
        type="text"
        id={id}
        variant="outlined"
        fullWidth
        size="small"
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        error={error}
        autoComplete={autoComplete}
      />
    </div>
  );
};
