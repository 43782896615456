// Password
export const PASSWORD_REQUIREMENTS =
  'Your password must be 8 or more characters long, should contain at least 1 uppercase,' +
  ' 1 lowercase, 1 number and 1 special character. No spaces are allowed, and it cannot be' +
  ' identical to your email address.';

export const SIGN_IN_TERMS =
  'This system may contain Government information, which is ' +
  'restricted to authorized users ONLY. Unauthorized access, use, misuse, or modification of ' +
  'this computer system or of the data contained herein or in transit to/from this system ' +
  'constitutes a violation of Title 18, United States Code Section 1030, and may subject ' +
  'the individual to Criminal and Civil penalties pursuant to Title 26, United States Code, ' +
  'Sections 7213, 7213A (the Taxpayer Browsing Protection Act), and 7431. This system and ' +
  'equipment are subject to monitoring to ensure proper performance of applicable security ' +
  'features or procedures. Such monitoring may result in the acquisition, recording and ' +
  'analysis of all data being communicated, transmitted, processed, or stored in this system ' +
  'by a user. If monitoring reveals possible evidence of criminal activity, such evidence ' +
  'may be provided to Law Enforcement Personnel. ANYONE USING THIS SYSTEM EXPRESSLY CONSENTS ' +
  'TO SUCH MONITORING.';
