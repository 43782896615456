// External
import { Routes, Route } from 'react-router-dom';

// Feature
import { RequestMail } from './RequestMail';

export const FormsRoutes = () => {
  return (
    <Routes>
      <Route path="request-mail" element={<RequestMail />} />
    </Routes>
  );
};
