// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import PaymentsReceivedImg from '../../assets/PaymentsReceived.webp';

export const PaymentsReceived = () => {
  return (
    <Accordion summary="How do I view my Payments Received?">
      <p className="mt-0">NCP payments which are applied are shown in Payments Received.</p>
      <LazyLoadImage src={PaymentsReceivedImg} alt="payments pending" height={350} />
    </Accordion>
  );
};
