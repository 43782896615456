// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import NcpBalances from '../../assets/NcpBalances.webp';

export const Balances = () => {
  return (
    <Accordion summary="How do I see my Balances?">
      <p className="mt-0">NCP balances for each case are shown in Balances</p>
      <LazyLoadImage src={NcpBalances} alt="ncp balance" height={350} />
    </Accordion>
  );
};
