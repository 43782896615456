// External
import { useMemo } from 'react';

// Project
import { getFilteredPaymentsReceivedData } from '../utils/payments';

// Feature
import { usePayments } from '../api';
import { FilteredPayments } from '../types';

export const useNcpFilteredPayments = (
  caseId: string,
  pin: string,
  isAppUserAgency: boolean
): FilteredPayments => {
  const { data: payments, isLoading, error } = usePayments(caseId, pin, isAppUserAgency);

  const data = useMemo(() => {
    if (isLoading === true && error == null) return;
    // eslint-disable-next-line eqeqeq
    if (payments != null) {
      return getFilteredPaymentsReceivedData(payments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments]);

  const results = {
    pendingPayments: data?.pendingPayments,
    receivedPayments: data?.receivedPayments,
    isLoading,
    error,
  };

  console.log('useNcpFilteredPayments', results);
  return results;
};
