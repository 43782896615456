// External
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

// Project
import { Spinner } from 'src/components/Spinner';
import { CenterLayout } from 'src/layout';

const FallbackComponent = () => (
  <CenterLayout>
    <Spinner />
  </CenterLayout>
);

export function SuspenseContainer() {
  return (
    <Suspense fallback={<FallbackComponent />}>
      <Outlet />
    </Suspense>
  );
}
