// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import NcpMailForms from '../../assets/NcpMailForms.webp';
import CpMailForms from '../../assets/CpMailForms.webp';
import MailFormsSuccess from '../../assets/MailFormsSuccess.webp';

export const Forms = () => {
  return (
    <Accordion summary="How do I request CSEA forms?">
      <p className="mt-0">
        Click on the Forms tab to see a list of available forms that can be sent to your mailing
        address on file.
      </p>
      <div className="flex gap-4">
        <LazyLoadImage src={NcpMailForms} alt="ncp mail forms" height={325} />
        <LazyLoadImage src={CpMailForms} alt="cp mail forms" height={325} />
      </div>
      <p>
        After making your selection, you will be shown a list of the forms that will be mailed to
        you.
      </p>
      <LazyLoadImage src={MailFormsSuccess} alt="mail form successful submission" height={350} />
    </Accordion>
  );
};
