// External
import React from 'react';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// Project
import { CenterLayout } from 'src/layout';
import { Spinner } from 'src/components/Spinner';
import { useUserType } from 'src/hooks';

// Feature
import { CenterRow, ParentFormTitle } from '../../components/RequestMail';
import { usePostMailRequests } from '../../api/postMailRequests';
import { useRequestedForms } from '../../api';
import { FormCodes } from '../../types/enum';

const SuccessRequest = ({ label }) => (
  <CenterRow>
    <CheckCircleIcon className="fill-green-600" /> {label}
  </CenterRow>
);

const FailedRequest = ({ label }) => (
  <CenterRow>
    <CancelIcon className="fill-red-600" /> {label}
  </CenterRow>
);

export const RequestMailConfirmation = ({ data, reset }) => {
  const { appUser, userIdentifier, caseId } = useParams();
  const isAppUserAgency = appUser === 'agency';
  const { data: userTypeData } = useUserType(caseId, userIdentifier, isAppUserAgency);
  const userType = userTypeData?.user_type || '';
  const { isLoading, results } = usePostMailRequests(caseId, userIdentifier, data, isAppUserAgency);
  const { isLoading: submittedFormsIsLoading, data: submittedForms } = useRequestedForms(
    caseId,
    userIdentifier,
    isAppUserAgency
  );

  if (isLoading || submittedFormsIsLoading) {
    return (
      <CenterLayout>
        <div>
          <p>Submitting</p>
          <div className="flex justify-center">
            <Spinner />
          </div>
        </div>
      </CenterLayout>
    );
  }

  return (
    <div>
      <p>Your request has been submitted!</p>
      <p>These forms will be mailed to the address on your account.</p>
      <ParentFormTitle userType={userType} />
      {submittedForms === null ? (
        <></>
      ) : (
        submittedForms.map((form, index) => {
          const matchingResult = results.find(
            (result) => result.formName === FormCodes[form.form_id]
          );
          if (matchingResult && matchingResult.status === 'fulfilled') {
            return <FailedRequest key={`form-request-${index}`} label={FormCodes[form.form_id]} />;
          }
          return <SuccessRequest key={`form-request-${index}`} label={FormCodes[form.form_id]} />;
        })
      )}

      {results.map((result, index) => {
        if (result.status !== 'fulfilled') {
          return <FailedRequest key={`form-request-${index}`} label={result.formName} />;
        }
        if (
          submittedForms &&
          submittedForms.find((form) => FormCodes[form.form_id] === result.formName)
        ) {
          return <React.Fragment key={`form-request-${index}`}></React.Fragment>;
        }
        return <SuccessRequest key={`form-request-${index}`} label={result.formName} />;
      })}
      <Button className="mt-7" variant="contained" onClick={reset}>
        Request another form
      </Button>
    </div>
  );
};
