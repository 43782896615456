// Project
import PATHS from 'src/config/paths';

// Feature
import { TabMenu } from './TabMenu';

export function UnlockMenu(props) {
  return (
    <TabMenu
      title="Unlock Accounts"
      activePath="/unlock/"
      activeStyle={props.activeStyle}
      inactiveStyle={props.inactiveStyle}
      itemList={[
        { name: 'For Sign In', path: PATHS.agencySignInUnlock },
        { name: 'For Linking Account', path: PATHS.agencyLinkUnlock },
      ]}
    />
  );
}
