// Project
import { LazyLoadImage } from 'src/components/Image';

// Feature
import { Accordion } from '../shared/Accordion';
import CpStatementImg from '../../assets/CpStatement.webp';

export const CpStatement = () => {
  return (
    <Accordion summary="How do I view my CP statement?">
      <p className="mt-0">
        If you received a CP Statement in the mail, it is available to view in the CP Statement tab
        and it may be reprinted by clicking the Print CP Statement button.
      </p>
      <LazyLoadImage src={CpStatementImg} alt="cp statement" height={550} />
    </Accordion>
  );
};
