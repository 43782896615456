// External
import { useEffect, useState } from 'react';

// Project
import { postHicData } from 'src/lib/fetch';

// Feature
import { FormCodes } from '../types/enum';

const matchFormIdToResults = (requestedForms: string[], results: PromiseSettledResult<any>[]) => {
  let matchedResults = requestedForms.map((formId, index) => ({
    formName: FormCodes[formId],
    ...results[index],
  }));

  return matchedResults;
};

const postRequest = async (
  formId: string,
  pin: string,
  caseNumber: string,
  isAppUserAgency: boolean
) => {
  const pinSearchParam = isAppUserAgency ? `&pin=${pin}` : '';
  const result = await postHicData(
    `/mail-request/form/${formId}?case_number=${caseNumber}${pinSearchParam}`,
    null
  );
  return result;
};

export const usePostMailRequests = (
  caseNumber: string,
  pin: string,
  data,
  isAppUserAgency: boolean
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [apiResults, setApiResults] = useState([]);

  useEffect(() => {
    Promise.allSettled(
      data.map((formId) => postRequest(formId, pin, caseNumber, isAppUserAgency))
    ).then((results) => {
      let matchedResults = matchFormIdToResults(data, results);
      setApiResults(matchedResults);
      setIsLoading(false);
    });
  }, [caseNumber, pin, data, isAppUserAgency]);

  let results = {
    isLoading,
    results: isLoading ? null : apiResults,
  };

  console.log('usePostMailRequests', results);
  return results;
};
