// External
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';

// Feature
import { getMetaData } from '../../utils/column';

export const getTableColumns = () => {
  const columns: MUIDataTableColumn[] = [
    {
      name: 'cp_pin',
      label: 'PIN',
      options: {
        sort: false,
      },
    },
    {
      name: 'cp_name',
      label: 'Name',
      options: {
        customBodyRender: (value: string, meta: MUIDataTableMeta) => {
          const pinValue = getMetaData('cp_pin', columns, meta);
          if (pinValue === 999999998) {
            return <span>{value}</span>;
          }
          return (
            <Link className="text-primary hover:text-primary" to={`/agency/${pinValue}/cases`}>
              {value}
            </Link>
          );
        },
        sort: false,
      },
    },
    {
      name: 'case_id',
      label: 'Case ID',
      options: {
        sort: false,
      },
    },
    {
      name: '',
      label: 'Role',
      options: {
        customBodyRender: () => {
          return 'Custodial Parent';
        },
        sort: false,
      },
    },
    {
      name: 'ncp_pin',
      label: 'NCP PIN',
      options: {
        display: false,
        sort: false,
      },
    },
    {
      name: 'ncp_name',
      label: 'Other Party',
      options: {
        customBodyRender: (value: string, meta: MUIDataTableMeta) => {
          return (
            <Link
              className="text-primary hover:text-primary"
              to={`/agency/${getMetaData('ncp_pin', columns, meta)}/cases`}
            >
              {value}
            </Link>
          );
        },
        sort: false,
      },
    },
    {
      name: 'ivd_flag',
      label: 'IV-D Status',
      options: {
        sort: false,
      },
    },
  ];

  return columns;
};
