// External
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

export const StatusTable = ({ data }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <p>Status is not available for this case at this time.</p>;
  }

  return (
    <div>
      <Table aria-label="status table">
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} className={index === data.length - 1 ? '[&>td]:border-b-0' : ''}>
              <TableCell>{item}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
