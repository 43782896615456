// Project
import { useHicData } from 'src/hooks';

// Feature
import { Disbursement } from '../types';

export const useDisbursements = (caseId: string) => {
  const queryParameters = {
    case_number: caseId,
  };

  const results = useHicData<Disbursement[]>('/disbursement', queryParameters);
  console.log('useDisbursements', results);
  return results;
};
