// External
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

// Project
import { CognitoProvider } from '../providers';

type ContainerUamProps = {
  children?: ReactElement;
};

export const ContainerUAM = ({ children }: ContainerUamProps) => (
  <CognitoProvider>{children ? children : <Outlet />}</CognitoProvider>
);
