// External
import { useQuery } from '@tanstack/react-query';

// Project
import { fetchData } from 'src/lib/fetch';
import { IHookError } from 'src/types';

export const useCaseData = <T>(path: string, queryParameters?: object) => {
  const results = useQuery<T, IHookError>({
    queryKey: ['case', path, queryParameters],
    queryFn: () => fetchData('case', path, queryParameters),
  });

  console.log('useCaseData', results);
  return results;
};
