// External
import React from 'react';
import { Text, View } from '@react-pdf/renderer';

// Project
import pdfStyles from 'src/themes/pdfStyles';
import { formatDate } from 'src/lib/date';

// Feature
import { AmountCell } from '../../components/PDF/AmountCell';

const tableProp = {
  // Header widths
  //// Longest date takes about 100
  disbursedDateWidth: 120,
  //// Amount takes about 70 without padding
  amountWidth: 85,
  amountPaddingRight: 20,
  //// Payee takes about 100
  //// covers: Custodial Parent, State of Hawaii
  //// NOTE: add other payees above as new payees discovered
  payeeWidth: 95,
  //// Description takes about 100
  //// covers: Direct Deposit, Check #
  //// NOTE: add other descriptions above as new descriptions discovered
  descriptionWidth: 110,
  //// Status takes about 90
  //// covers: ETF sent to Bank, Uncashed
  //// NOTE: add other statuses above as new statuses discovered
  statusWidth: 90,
};

const {
  font_bold,
  text_m,
  pb_4,
  flex_row,
  pl_2,
  pb_3,
  p_2,
  pr_11,
  border_t,
  justify_between,
  justify_start,
} = pdfStyles;

const TableHeader = () => (
  <View style={[flex_row, pl_2, pb_3, justify_start]}>
    <Text style={[{ width: tableProp.disbursedDateWidth }, font_bold]}>Disbursement Date</Text>
    <Text style={[{ width: tableProp.amountWidth }, font_bold]}>Amount</Text>
    <Text style={[{ width: tableProp.payeeWidth }, font_bold]}>Payee</Text>
    <Text style={[{ width: tableProp.descriptionWidth }, font_bold]}>Description</Text>
    <Text style={[{ width: tableProp.statusWidth }, font_bold]}>Status</Text>
  </View>
);

const SubTableBody = ({ data }): any => {
  return [...Array(5).keys()].map((_, index) => {
    const amount = data[`amt_issue_${index + 1}`];
    return +amount > 0 ? (
      <View key={index} style={[flex_row, p_2, border_t]}>
        <View
          style={[
            {
              width: tableProp.disbursedDateWidth,
            },
            flex_row,
            justify_between,
            pr_11,
          ]}
        />
        <AmountCell
          width={tableProp.amountWidth}
          amount={amount}
          paddingRight={tableProp.amountPaddingRight}
        />
        <Text style={{ width: tableProp.payeeWidth }}>Case # {data[`case_nbr_${index + 1}`]}</Text>
      </View>
    ) : null;
  });
};

const TableBody = ({ disbursements }) => {
  return disbursements
    .slice(0)
    .reverse()
    .map((disbursement, index) => (
      <React.Fragment key={index}>
        <View style={[flex_row, p_2, pl_2, border_t]}>
          <Text style={{ width: tableProp.disbursedDateWidth }}>
            {formatDate(disbursement.disb_date, 'YYYYMMDD', 'MMMM D, YYYY')}
          </Text>
          <AmountCell
            width={tableProp.amountWidth}
            amount={disbursement.disb_amt}
            paddingRight={tableProp.amountPaddingRight}
          />
          <Text style={{ width: tableProp.payeeWidth }}>{disbursement.payee}</Text>
          <Text style={{ width: tableProp.descriptionWidth }}>{disbursement.description}</Text>
          <Text style={{ width: tableProp.statusWidth }}>{disbursement.status}</Text>
        </View>
        {disbursement.case_nbr_2 !== 0 && <SubTableBody data={disbursement} />}
      </React.Fragment>
    ));
};

export const DisbursementTable = ({ disbursements }) => {
  if (disbursements === null) {
    return (
      <>
        <Text style={[font_bold, text_m, pb_4]}>Disbursements</Text>
        <Text>No disbursements were found within the last 12 months.</Text>
      </>
    );
  }

  return (
    <>
      <Text style={[font_bold, text_m, pb_4]}>Disbursements</Text>
      <TableHeader />
      <TableBody disbursements={disbursements} />
    </>
  );
};
