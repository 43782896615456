// Project
import { Spinner } from 'src/components/Spinner';

export const TableBodySpinner = () => (
  <tbody className="relative h-[40vh]">
    <tr>
      <td>
        <div className="absolute top-1/4 left-1/2">
          <Spinner />
        </div>
      </td>
    </tr>
  </tbody>
);
