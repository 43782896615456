// External
import { Route, Routes } from 'react-router-dom';

// Project
import { LinkingUnlock } from './LinkingUnlock';
import { SignInUnlock } from './SignInUnlock';

export const AgencyUnlockRoutes = () => (
  <Routes>
    <Route path="link-account" element={<LinkingUnlock />} />
    <Route path="sign-in" element={<SignInUnlock />} />
  </Routes>
);
