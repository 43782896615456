// External
import { createTheme } from '@mui/material/styles';

// Local
import { PRIMARY_COLOR } from './global-constants';

// https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

// https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

const rootElement = document.getElementById('root');

const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'Segoe UI', 'Tahoma', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    neutral: {
      main: '#BDBDBD',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        head: {
          fontWeight: '600',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

export default theme;
