// External
import { useContext, useState } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';

// Features
import { CognitoContext } from '../providers';
import { signOut } from 'src/lib/auth';

const TotpMfa = () => {
  const navigate = useNavigate();
  const { cognitoObj, updateCognitoObj } = useContext(CognitoContext);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleConfirm = async (event) => {
    try {
      event.preventDefault();
      await Auth.confirmSignIn(cognitoObj, code, 'SOFTWARE_TOKEN_MFA');
      updateCognitoObj(undefined);
      navigate(PATHS.onVpn);
    } catch (err) {
      console.log('confirmError', err);
      setError('Invalid code, please enter again.');
    }
  };

  const handleOnChange = (e) => {
    setCode(e.target.value);
  };

  const handleOnBackToSignIn = () => {
    signOut();
  };

  return (
    <>
      <h1 className="text-center">Confirm MFA Code</h1>
      <p className="mb-3">Please enter the code from Authenticator: 2FA Client</p>
      <form onSubmit={handleConfirm}>
        <TextInput
          name="code"
          label="Code"
          value={code}
          placeholder="Authentication Code"
          required
          onChange={handleOnChange}
          error={error !== ''}
        />
        {error && <div className="text-red-600">{error}</div>}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Confirm
        </Button>
      </form>
      <div className="mt-5 flex justify-between">
        <Link
          to={PATHS.signIn('agency')}
          onClick={handleOnBackToSignIn}
          className="text-primary no-underline"
        >
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

export default TotpMfa;
