// External
import { Container, Box } from '@mui/material';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Project
import Header from 'src/components/Header';
import PATHS from 'src/config/paths';
import { MainNavBar } from 'src/components/Navigation';
import { Footer } from 'src/components/Footer';
import { DefaultError, NotAuthorizedError } from 'src/components/Error';
import { FaqButton } from 'src/components/Button';

function ErrorFallback({ error }) {
  switch (error.message) {
    case 'NotAuthorized':
      return <NotAuthorizedError />;
    case 'NotFound':
      return <div>Not Found</div>;
    default:
      return <DefaultError />;
  }
}

interface MainLayoutProps {
  children?: React.ReactNode;
}
export const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const { appUser } = useParams();
  const isNotAgencyUser = appUser !== 'agency';
  const isFaqPage = location.pathname.includes(PATHS.faq);
  const showFaqBtn = isNotAgencyUser && !isFaqPage;

  return (
    <div className="h-screen">
      <Container className="pt-8">
        <Header />
        <MainNavBar />
        <Box className="min-h-[65vh] bg-white p-8">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children ? children : <Outlet />}
          </ErrorBoundary>
        </Box>
        <Footer />
      </Container>
      {showFaqBtn && (
        <div className="mt-5 flex justify-center md:fixed md:bottom-8 md:right-8">
          <FaqButton />
        </div>
      )}
    </div>
  );
};
