// Feature
import { Login } from './Login';
import { CreateAccount } from './CreateAccount';
import { ForgotPassword } from './ForgotPassword';
import { Lockout } from './Lockout';
import { Hearings } from './Hearings';
import { Forms } from './Forms';
import { Enforcement } from './Enforcement';
import { Profile } from './Profile';

export const General = () => {
  return (
    <div>
      <Login />
      <CreateAccount />
      <ForgotPassword />
      <Lockout />
      <Hearings />
      <Forms />
      <Enforcement />
      <Profile />
    </div>
  );
};
