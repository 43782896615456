// External
import { Paper } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';

// Project
import { FaqButton } from 'src/components/Button';

// Features
import { HeaderUam } from '../components';

export const UamLayout = () => {
  const { appUser } = useParams();
  const isNotAgencyUser = appUser !== 'agency';

  return (
    <div className="h-screen pt-20">
      <Paper className="mx-auto max-w-xl bg-white p-8">
        <HeaderUam />
        <main>
          <Outlet />
        </main>
      </Paper>
      {isNotAgencyUser && (
        <div className="mt-5 flex justify-center md:fixed md:bottom-8 md:right-8">
          <FaqButton />
        </div>
      )}
    </div>
  );
};
