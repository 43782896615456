import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const RedirectLink = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const url = searchParams.get('url');
    if (url) window.location.replace(searchParams.get('url'));
    else window.location.replace('/');
  }, [searchParams]);

  return <div />;
};

export default RedirectLink;
